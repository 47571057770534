import React, { useContext, useState } from "react";
import { EuiFieldText } from "@edhos/ui";
import { AppContext } from "../context/app-context";

const TextInput = (props) => {
  const context = useContext(AppContext);

  const focus = () => {
    context.setFocusTextInput(props.inputRef);
  };

  return (
    <EuiFieldText
      {...props}
      onFocus={(e) => {
        focus();
        props.onFocus(e);
      }}
    />
  );
};

export default TextInput;

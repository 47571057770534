import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { EuiFieldText, EuiPortal } from '@edhos/ui';
import App from '../App';
import Bills from '../pages/bills';
// import Bpjs from "../pages/bpjs";
import KioskBooking from '../pages/kiosk-booking';
import Regular from '../pages/regular';
import BelumBooking from '../pages/regular/belum-booking';
import RegistrationCounter from '../pages/registration-counter';
import { AppContext } from '../context/app-context';
import Numpad from '../components/Numpad';
import KeyboardOnScreen from '../components/KeyboardOnScreen';
import { usePrevious } from '../hooks';

import moment from 'moment-timezone';
import SelfService from '../pages/self-service-counter';
import OnlineCheckin from '../pages/online-checkin-counter';
import { useEffect } from 'react';
import AuthForm from '../pages/AuthForm';
import JknKiosk from '../pages/jkn-kiosk';

moment.tz.setDefault('Asia/Jakarta');
moment.locale('id', {
  months: [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ],
});

function BaseLayout() {
  const [input, setInput] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [passcode, setPasscode] = useState('');

  const [showNumpad, setShowNumpad] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [defaultValueText, setDefaultValueText] = useState('');

  const [wasSearchMedrek, setWasSearchMedrek] = useState(false);
  const [wasBooking, setWasBooking] = useState(false);
  const [oldPatient, setOldPatient] = useState(false);
  const [dataPatient, setDataPatient] = useState(null);
  // const [identityNumber, setIdentityNumber] = useState("");
  const [dob, setDob] = useState('');
  const [fullName, setFullName] = useState('');
  const [norm, setNorm] = useState('');
  const [invNumber, setInvNumber] = useState('');
  const [klinik, setKlinik] = useState({});
  const [doctor, setDoctor] = useState(null);

  const [listDataPatient, setListDataPatient] = useState([]);

  // registrationCounter
  const [typePenjamin, setTypePenjamin] = useState('asuransi');

  //online checkin
  const [bookingVia, setBookingVia] = useState('web'); // or wa
  const [selectedPatientReg, setSelectedPatientReg] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // authentication
  useEffect(() => {
    async function getToken() {
      let tokenAuth = await localStorage.getItem('token_auth');
      const decodeToken = window.atob(tokenAuth);
      if (decodeToken === process.env.REACT_APP_PASS_CODE) {
        setIsAuthenticated(true);
      }
    }

    getToken();
  }, []);

  const setFocusInput = (ref) => {
    if (showNumpad) {
      setShowNumpad(false);
    }
    if (showKeyboard) {
      setShowKeyboard(false);
    }
    setShowNumpad(true);
    setInput(ref);
    console.log(ref.current);
  };

  const setFocusTextInput = (ref) => {
    setShowKeyboard(false);
    if (showNumpad) {
      setShowNumpad(false);
    }
    if (showKeyboard) {
      setShowKeyboard(false);
    }
    // setShowKeyboard(true);
    setInput(ref);
    setTimeout(() => setShowKeyboard(true), 100);
    console.log(ref.current.getAttribute('name'));
  };

  const deactiveKeyboard = () => {
    setInput(null);
    setShowKeyboard(false);
  };

  const deactiveNumpad = () => {
    setInput(null);
    setShowNumpad(false);
  };

  const handleRespionseKeyboard = (value) => {
    switch (input.current.getAttribute('name')) {
      // case "identityNumber":
      //   setIdentityNumber((state) => (state += value));
      //   break;
      case 'search_keyword':
        setSearchKeyword(value);
        break;
      case 'dob':
        setDob((state) => {
          let old = state;
          let newVal = value;
          let merge = (old += newVal);
          if (merge.length === 8) {
            deactiveNumpad();
          }
          if (merge.length <= 8) {
            return (state += value);
          }
        });
        break;
      case 'invNumber':
        // setInvNumber((state) => (state += value));
        setInvNumber(value);
        // input.current.value = value
        // input.current.focus()
        break;
      case 'norm':
        setNorm((state) => (state += value));
        break;
      case 'fullname':
        setFullName(value);
        break;
      case 'passcode':
        setPasscode(value);
        break;
      default:
    }
  };

  const handleTapDelKeyboard = () => {
    switch (input.current.getAttribute('name')) {
      // case "identityNumber":
      //   setIdentityNumber(
      //     identityNumber.substring(0, identityNumber.length - 1)
      //   );
      //   break;
      case 'dob':
        setDob(dob.substring(0, dob.length - 1));
        break;
      case 'norm':
        setNorm(norm.substring(0, norm.length - 1));
        break;
      default:
    }
  };

  const parsedDate = (string) => {
    const dd = string.substring(0, 2);
    const mm = string.substring(2, 4);
    const yyyy = string.substring(4, 8);
    return `${yyyy}-${mm}-${dd}`;
  };

  const reset = () => {
    setBookingVia('web');
    setSelectedPatientReg(null);
    setWasSearchMedrek(false);
    setWasBooking(false);
    setDataPatient(null);
    setOldPatient(false);
    // setIdentityNumber("");
    setSearchKeyword('');
    setFullName('');
    setDob('');
    setInvNumber('');
    setKlinik({});
    setDoctor(null);
    deactiveNumpad();
    deactiveKeyboard();
  };

  const appContext = {
    input,
    moment,
    reset,
    listDataPatient,
    setListDataPatient,
    wasSearchMedrek,
    setWasSearchMedrek,
    setWasBooking,
    wasBooking,
    parsedDate,
    setDataPatient,
    dataPatient,
    regular: {
      searchKeyword,
      setSearchKeyword,
      fullName,
      setFullName,
      setOldPatient,
      oldPatient,
      // setIdentityNumber,
      // identityNumber,
      setDob,
      dob,
      setInvNumber,
      invNumber,
      setKlinik,
      klinik,
      setDoctor,
      doctor,
    },
    payment: {
      norm,
      setNorm,
    },
    registrationCounter: {
      typePenjamin,
      setTypePenjamin,
    },
    onlineCheckin: {
      setBookingVia,
      bookingVia, // or wa
      setSelectedPatientReg,
      selectedPatientReg,
    },
    defaultValueText,
    setDefaultValueText,
    deactiveKeyboard,
    deactiveNumpad,
    setFocusTextInput,
    setFocusInput,
    setShowKeyboard,
    showKeyboard,
    setShowNumpad,
    showNumpad,
    passcode,
    setPasscode,
    setIsAuthenticated,
    isAuthenticated,
  };

  let numpadLayout, keyboardLayout;

  if (showKeyboard) {
    keyboardLayout = (
      <KeyboardOnScreen
        value={defaultValueText}
        response={(val) => handleRespionseKeyboard(val)}
        onCloseKeyboard={() => deactiveKeyboard()}
      />
    );
  }

  if (showNumpad) {
    numpadLayout = (
      <Numpad
        inputRef={input}
        response={(val) => handleRespionseKeyboard(val)}
        deactiveNumpad={() => deactiveNumpad()}
        onTapdel={handleTapDelKeyboard}
      />
    );
  }

  return (
    <AppContext.Provider value={appContext}>
      {!isAuthenticated && <AuthForm />}
      {isAuthenticated && (
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={<App />}
            />
            <Route
              path='/bills'
              element={<Bills />}
            />
            <Route
              path='/regular'
              element={<Regular />}
            />
            <Route
              path='/belum-booking'
              element={<BelumBooking />}
            />
            {/* <Route path="/bpjs" element={<Bpjs />} /> */}
            <Route
              path='/kiosk-booking'
              element={<KioskBooking />}
            />
            <Route
              path='/registration-counter'
              element={<RegistrationCounter />}
            />
            <Route
              path='/self-service-counter'
              element={<SelfService />}
            />
            <Route
              path='/online-checkin-counter'
              element={<OnlineCheckin />}
            />
            <Route
              path='/jkn-kiosk'
              element={<JknKiosk />}
            />
          </Routes>
        </BrowserRouter>
      )}

      <EuiPortal>
        {keyboardLayout}
        {numpadLayout}
      </EuiPortal>
    </AppContext.Provider>
  );
}

export default BaseLayout;

import { twMerge } from "tailwind-merge"


const ItemMenu = ({ title, description, icon, className, onClick }) => {

  return (
    <li onClick={onClick} className={`relative bg-white border border-slate-200 rounded-lg flex items-center px-12 py-12 cursor-pointer hover:shadow-xl hover:scale-105 transition-all`}>
      <div className={twMerge('flex items-center justify-center w-24 h-24 bg-primary/10 text-primary rounded-lg', className)}>
        {icon}
      </div>
      <div className='ml-8'>
        <h4 className='font-semibold text-slate-800/80 text-5xl mb-3'>{title}</h4>
        <p className='opacity-70 text-slate-800/80 text-4xl'>{description}</p>
      </div>

    </li>
  )
}

export default ItemMenu
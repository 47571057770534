import React, { useContext, useRef, useState } from "react";
import { EuiFormRow, EuiButton } from "@edhos/ui";
import { AppContext } from "../context/app-context";
import { TextInput } from "../components";

const AuthForm = () => {
  const context = useContext(AppContext);
  const [errorPasscode, setErrorPasscode] = useState("");
  const [isCheckingPasscode, setIsCheckingPasscode] = useState(false);
  const passcode_ref = useRef(null);

  const handlePasscode = ({ target: { value } }) => {
    context.setPasscode(value);
  };

  const handleOnFocusPasscode = ({ target: { value } }) =>
    context.setDefaultValueText(value);

  const checkPasscode = () => {
    const str = context.passcode;
    setIsCheckingPasscode(true);

    if (str === process.env.REACT_APP_PASS_CODE) {
      const decode = window.btoa(str);
      const saveItem = localStorage.setItem("token_auth", decode);
      context.setIsAuthenticated(true);
      context.setShowKeyboard(false);
    } else {
      setErrorPasscode("Pass Code Salah!");
    }
    setIsCheckingPasscode(false);
  };

  return (
    <div className='h-screen flex justify-center items-center'>
      <div>
        <EuiFormRow label='Pass Code' className='mx-auto' error={errorPasscode}>
          <TextInput
            inputRef={passcode_ref}
            value={context.passcode}
            name='passcode'
            onChange={handlePasscode}
            onFocus={handleOnFocusPasscode}
            autoComplete='off'
            maxLength={6}
          />
        </EuiFormRow>
        <div className='flex justify-center mt-12'>
          <EuiButton
            iconType='push'
            iconSide='right'
            onClick={checkPasscode}
            color='primary'
            isLoading={isCheckingPasscode}
          >
            {isCheckingPasscode && "Mohon tunggu"}
            {!isCheckingPasscode && "Masuk"}
          </EuiButton>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;

import React, { useContext, useRef, useEffect, useState } from "react";
import {
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiCard,
  EuiI,
  EuiIcon,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiTextColor,
  EuiText,
  EuiHorizontalRule,
} from "@edhos/ui";
import Moment from "react-moment";
import { AppContext } from "../../../context/app-context";
import { DateInput, IdentityNumberInput } from "../../../components";
import TransactionInput from "../../../components/TransactionInput";
import global from "../../../services/global";

const One = function (props) {
  const context = useContext(AppContext);
  const [isLoadingRegisterNumber, setLoadingRegisterNumber] = useState(false);
  const [dataAppointment, setDataAppointment] = useState(null);
  const identity_number_ref = useRef(null);
  const dob_ref = useRef(null);
  const invNumber_ref = useRef(null);

  // invNumber_ref.current.focus();

  const onFocus = (ref) => {
    // context.activeNumpad(ref);
  };

  const deactiveNumpad = () => context.deactiveNumpad();

  const onBlur = () => {
    // context.deactiveNumpad();
    // console.log("blur");
  };

  useEffect(() => {
    if (context.wasBooking) {
      setTimeout(() => {
        invNumber_ref.current.focus();
      }, 500);
    }
  }, []);

  const handleIdentityNumber = ({ target: { value } }) => {
    context.regular.setIdentityNumber(value);
  };

  const handleDob = ({ target: { value } }) => context.regular.setDob(value);
  const handleInvNumber = ({ target: { value } }) => {
    context.regular.setInvNumber(value);
    if (value.length > 15) {
      debouncedSave(value);
    }
  };

  const debouncedSave = useRef(
    debounce((nextValue) => checkNumberRegis(nextValue), 1000)
  ).current;

  function debounce(func, wait) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const checkNumberRegis = async (nextValue) => {
    // .get("transaksi/appointment/cari?code=APTINV20211102/PAC301/00187")
    const keyword = nextValue.includes("APTINV")
      ? nextValue
      : `APTINV${nextValue}`;
    setLoadingRegisterNumber(true);
    console.log("start");
    // global
    //   .get(`transaksi/appointment/cari?code=${keyword}`)
    //   .then((res) => console.log("res", res))
    //   .catch((er) => console.log("err", er));
    try {
      const { data } = await global.get(
        `transaksi/appointment/cari?code=${keyword}`
      );
      setLoadingRegisterNumber(false);
      setDataAppointment(data.results[0]);
      console.log("results", data.results);
      invNumber_ref.current.blur();
    } catch (e) {
      if (e.message === "Request failed with status code 404") {
        console.log("notfound", e.message);
        setDataAppointment(null);
        setLoadingRegisterNumber(false);
      }
    }
  };

  return (
    <div className='pt-24 mx-auto w-1/2 mt-24'>
      {!context.wasBooking && (
        <div>
          <EuiFormRow label='No. KTP' className='mx-auto'>
            <IdentityNumberInput
              inputRef={identity_number_ref}
              onBlur={onBlur}
              value={context.regular.identityNumber}
              onChange={handleIdentityNumber}
              name='identityNumber'
            />
          </EuiFormRow>
          <EuiFormRow label='Tanggal lahir' className='mx-auto'>
            <DateInput
              inputRef={dob_ref}
              value={context.regular.dob}
              onChange={handleDob}
              name='dob'
            />
          </EuiFormRow>
        </div>
      )}
      {context.wasBooking && (
        <>
          <EuiFormRow label='Kode registrasi' className='mx-auto'>
            <TransactionInput
              id='invNumber'
              isLoading={isLoadingRegisterNumber}
              inputRef={invNumber_ref}
              value={context.regular.invNumber}
              onChange={handleInvNumber}
              name='invNumber'
              sparator='APTINV'
            />
          </EuiFormRow>
          {dataAppointment !== null && (
            <div className='mt-24'>
              <EuiCard
                textAlign='left'
                title={dataAppointment.poli[1]}
                description=''
                footer={
                  <>
                    <EuiSpacer size='m' />
                    <EuiFlexGroup justifyContent='center'>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          fill
                          iconType='logstashOutput'
                          onClick={() =>
                            (window.location.href = "/kiosk-booking")
                          }
                        >
                          Cetak
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                }
              >
                <>
                  <EuiSpacer size='m' />
                  <EuiText grow={false}>
                    <dl className='eui-definitionListReverse'>
                      <dt>Dokter</dt>
                      <dd>{dataAppointment.doctor[1]}</dd>
                      <dt>Pasien</dt>
                      <dd>{dataAppointment.patient.name}</dd>
                      <dt>Waktu Janji</dt>
                      <dd>
                        <Moment
                          format='DD MMM YYYY, hh:mm'
                          date={dataAppointment.waktu_estimasi}
                        />
                      </dd>
                      <dt>Nomor Antrian</dt>
                      <dd className='text-3xl text-gray-600 font-bold'>
                        {dataAppointment.no_antrian}
                      </dd>
                    </dl>
                  </EuiText>
                </>
              </EuiCard>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default One;

import axios from "axios";

// HttpClient
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    access_token: process.env.REACT_APP_ACCESS_TOKEN,
  },
});

export default api;

import React, { useContext, useEffect, useState } from "react";
import {
  EuiPortal,
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiPanel,
  EuiCodeBlock,
  EuiModalFooter,
  EuiFlexGroup,
  EuiFlexGrid,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTextAlign,
  EuiTextColor,
  EuiImage,
  EuiAvatar,
} from "@edhos/ui";
import api from "../../../services/api";
import { AppContext } from "../../../context/app-context";
import LoaderContent from "../../../components/LoaderContent";
// import logo from "../../../logo.png";

const Three = function (props) {
  const context = useContext(AppContext);

  const [klinik, setKlinik] = useState([]);
  const [loadKlinik, setLoadKlinik] = useState(true);
  const [klinikActive, setKlinikActive] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  useEffect(() => {
    api
      .get("edelweiss.poli/get?filters=[('line_type','=','onsite')]")
      .then(({ data }) => {
        setLoadKlinik(false);
        setKlinik(data.results);
      });
  }, []);

  const chooseKlinik = (klinik) => {
    context.regular.setKlinik({ id: klinik.id, name: klinik.name });
  };

  const listDoctor = (klinik) => {
    setKlinikActive(klinik);
    showModal();
  };

  const onClickDoctor = (doc) => {
    context.regular.setKlinik({ id: klinikActive.id, name: klinikActive.name });
    context.regular.setDoctor({
      id: doc.name,
      name: doc.name,
      photo: doc.photo,
    });
    closeModal();
  };

  let modal, panel;

  if (isModalVisible) {
    modal = (
      <EuiModal style={{ width: 300 }} onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>{klinikActive.name}</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          {/* This modal has the following setup: */}
          <EuiText color='subdued'>
            <p>
              Silahkan pilih dokter, dengan cara <b>Tap</b>
            </p>
          </EuiText>
          <EuiSpacer />
          <EuiFlexGroup direction='column'>
            {klinikActive.available_lines.map((doc, index) => {
              return (
                <EuiFlexItem
                  grow={false}
                  key={doc.id}
                  onClick={() => onClickDoctor(doc)}
                >
                  <EuiFlexGroup gutterSize='s' alignItems='center'>
                    <EuiAvatar
                      size='l'
                      name={doc.name}
                      imageUrl={doc.photo}
                      className='mr-4'
                    />

                    <EuiText grow={false} size='m'>
                      <EuiTextColor color='subded'>
                        <h4>{doc.name}</h4>
                      </EuiTextColor>
                    </EuiText>
                  </EuiFlexGroup>
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Tutup
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }
  if (
    context.regular.doctor?.name &&
    props.currentScrollY < 60 &&
    props.activeIndex === 2
  ) {
    panel = (
      <div className='fixed mx-auto bottom-8 inset-x-0 z-10 w-1/2 slide-up'>
        <EuiPanel paddingSize='m'>
          <EuiText color='accent'>
            <h4>{context.regular.doctor.name}</h4>
          </EuiText>
          <EuiText color='subdued' className='mt-1'>
            <h6>{context.regular.klinik.name}</h6>
          </EuiText>
        </EuiPanel>
      </div>
    );
  }

  return (
    <div className='pt-24'>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        {/* <EuiImage size={200} alt='Logo edelweiss hospital' src={logo} /> */}
        <EuiText grow={false}>
          <EuiTextAlign textAlign='center'>
            <h1>Mau ke Poliklinik mana?</h1>
            <EuiText color='subdued'>
              <p>
                Silahkan pilih, dengan cara <b>Tap</b> nama <b>Kliniknya</b>
              </p>
            </EuiText>
          </EuiTextAlign>
        </EuiText>
      </EuiFlexGroup>
      <div className='my-20'></div>
      <EuiPortal>{panel}</EuiPortal>
      {modal}
      <EuiFlexGrid gutterSize='xl' columns={3}>
        {loadKlinik &&
          [1, 2, 4].map((val) => (
            <EuiFlexItem key={val.id}>
              <LoaderContent />
            </EuiFlexItem>
          ))}
        {!loadKlinik &&
          klinik.map((val) => {
            return (
              <EuiFlexItem key={val.id}>
                <EuiCard
                  onClick={() => listDoctor(val)}
                  title={val.name}
                  className={
                    context.regular.klinik.id == val.id
                      ? "selected-card"
                      : "border-card"
                  }
                  textAlign='left'
                  footer={
                    <div>
                      <EuiSpacer size='s' />
                      <EuiText grow={false} size='m'>
                        <EuiTextColor color='subdued'>
                          <h6>Dokter</h6>
                        </EuiTextColor>
                      </EuiText>
                      <EuiSpacer size='s' />
                      <EuiFlexGroup gutterSize='s'>
                        {val.available_lines.map((doc, index) => {
                          if (index < 3) {
                            return (
                              <EuiFlexItem grow={false} key={doc.id}>
                                <EuiAvatar
                                  size='l'
                                  name={doc.name}
                                  imageUrl={doc.photo}
                                />
                              </EuiFlexItem>
                            );
                          }
                          if (index === 4) {
                            return (
                              <EuiFlexItem grow={false}>
                                <EuiAvatar
                                  size='l'
                                  name={val.available_lines.length - 3 + "+"}
                                  imageUrl={`https://ui-avatars.com/api/?color=ed2985&background=d9d9d9&name=${
                                    val.available_lines.length - 3
                                  }`}
                                />
                              </EuiFlexItem>
                            );
                          }
                        })}
                      </EuiFlexGroup>
                    </div>
                  }
                />
              </EuiFlexItem>
            );
          })}
      </EuiFlexGrid>
    </div>
  );
};

export default Three;

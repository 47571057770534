import React, { useContext, useEffect, useState } from "react";
import {
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiImage,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
} from "@edhos/ui";
import logo from "../../../logo.png";
import { AppContext } from "../../../context/app-context";
import global from "../../../services/global";
import TemplatePrintPoliklinik from "../../../components/TemplatePrintPoliklinik";

const One = function (props) {
  const context = useContext(AppContext);
  const [timeLeft, setTimeLeft] = useState(10);
  const [isModalGuideWhatsNextVisible, setIsModalGuideWhatsNextVisible] =
    useState(false);
  const [isModalSummaryVisible, setIsModalSummaryVisible] = useState(false);
  const [loadingPostRegister, setLoadingPostRegister] = useState(false);

  let modalSummary = "",
    modalGuideWhatsNext = "",
    intervalGuide = null;

  const startTimerGuide = () => {
    intervalGuide = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft < 1) {
      clearInterval(timeLeft);
      window.location.href = "/kiosk-booking";
    }
  }, [timeLeft]);

  const postRegister = () => {
    setLoadingPostRegister(true);
    global.get(`web/no_antrian?tipe=umum`).then((res) => {
      setIsModalSummaryVisible(false);
      setLoadingPostRegister(false);
      if (res.data.response === "failed") {
      } else {
        window.open(
          `/antrian-label-patient-regular.html?noantrian=${
            res.data.results[0].urutan
          }&penjamin=UMUM&date=${context
            .moment()
            .format("DD MMMM YYYY HH:mm:ss")}`,
          "name",
          "width=500,height=500"
        );
        // window.location.reload();
        // window.location.href = "/kiosk-booking";
        setIsModalGuideWhatsNextVisible(true);
        startTimerGuide();
      }
      setLoadingPostRegister(false);
    });
  };

  if (isModalSummaryVisible) {
    modalSummary = (
      <EuiModal onClose={() => setIsModalSummaryVisible(false)}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Sudah benar?</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TemplatePrintPoliklinik>
            <h2 className='text-2xl font-semibold'>ANTRIAN UMUM</h2>
            <br />
            <h4 className='font-semibold'>
              {context.moment().format("DD MMMM YYYY H:m:s")}
            </h4>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={postRegister}
                isLoading={loadingPostRegister}
                color='accent'
              >
                Ya, Cetak antrian
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isModalGuideWhatsNextVisible) {
    modalGuideWhatsNext = (
      <EuiModal onClose={() => null}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Langkah selanjutnya</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TemplatePrintPoliklinik>
            <h2 className='text-2xl font-semibold'>
              Silahkan mengunjungi ke bagian pendaftaran
            </h2>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              Menutup Otomatis dalam ({timeLeft} detik)
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <div className=''>
      {modalGuideWhatsNext}
      {modalSummary}
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <EuiText textAlign='center' grow={false}>
          <h1>Silahkan pilih apakah kamu Pasien Lama atau Pasien Baru</h1>
        </EuiText>
      </EuiFlexGroup>
      <div className='my-20'></div>
      <div className='my-20'></div>
      <EuiFlexGroup gutterSize='xl'>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='agentApp' />}
            title='Pasien Lama'
            description='Pernah menjadi pasien di Edelweiss Hospital'
            footer={
              <div>
                <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Pasien lama tap disini'
                  onClick={() => {
                    props.beforeNext();
                    context.regular.setOldPatient(true);
                  }}
                >
                  Tap disini
                </EuiButton>
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='filebeatApp' />}
            title='Pasien Baru'
            description='Belum pernah berkunjung ke Edelweiss Hospital'
            footer={
              <div>
                <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Pasien baru tap disini'
                  onClick={() => {
                    setIsModalSummaryVisible(true);
                  }}
                  color='accent'
                >
                  Cetak antrian
                </EuiButton>
              </div>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default One;

import React from "react";
import ContentLoader from "react-content-loader";

const LoaderContent = (props) => (
  <ContentLoader
    speed={2}
    width={360}
    height={220}
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='60' rx='2' ry='2' width='360' height='220' />
  </ContentLoader>
);
export default LoaderContent;

import React, { useContext, useEffect, useState } from 'react';
import {
  EuiFlexGroup,
  EuiText,
  EuiIcon,
  EuiSpacer,
  EuiFlexItem,
  EuiCard,
  EuiButton,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiPortal,
} from '@edhos/ui';
import TemplatePrintPoliklinik from '../../../components/TemplatePrintPoliklinik';
import { AppContext } from '../../../context/app-context';
import global from '../../../services/global';
import api from '../../../services/api';
import { convertIntToRm } from '../../../Helper';
import { useNavigate } from 'react-router-dom';

const Three = function (props) {
  const navigate = useNavigate()
  const context = useContext(AppContext);
  const [isModalGuideWhatsNextVisible, setIsModalGuideWhatsNextVisible] =
    useState(false);
  const [timeLeft, setTimeLeft] = useState(8);
  const [loadingGetAntrean, setLoadingGetAntrean] = useState(false);
  const [typePenjamin, setTypePenjamin] = useState('UMUM');

  const [errorAntrean, setErrorAntrean] = useState('');

  let modalGuideWhatsNext = null,
    intervalGuide;

  const startTimerGuide = () => {
    intervalGuide = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft < 1) {
      clearInterval(timeLeft);

      context.reset();
      // props.swiperSlideTo(0);
      navigate('/')
    }
  }, [timeLeft]);

  const cetakAntrean = (payload) => {
    setTypePenjamin(payload);
    const appointment = context.onlineCheckin.selectedPatientReg;
    if (payload === 'UMUM') {

      if (appointment.department[0].id === 172562) { // 172562 = id department poli MCU
        cetakAntreanAddmission()
      } else {
        cetakAntreanPoli();
      }

    } else {
      cetakAntreanAddmission();
    }
  };

  const checkInPatient = async (param) => {
    // /api/edelweiss.appointment.transaction/check_in?code=<kode_booking>
    const { data } = await api.get(
      // `edelweiss.appointment.transaction/check_in?code=${dataAppointment.code}`
      `edelweiss.appointment.transaction/check_in${param}`
    );

    if (data.response === 'failed') {
      throw new Error(data.message);
    } else {
      return data;
    }
  };

  const cetakAntreanPoli = async () => {
    setLoadingGetAntrean(true);
    setErrorAntrean('');
    console.log('cetakAntreanPoli')

    const appointment = context.onlineCheckin.selectedPatientReg;
    try {
      const checkin_antrian = await checkInPatient(
        `?dsid=${appointment.doctor_schedule.dsid}&no_rm=${convertIntToRm(
          appointment.pid
        )}&tgl_janji=${context
          .moment(appointment.tgl_janji)
          .format('YYYY-MM-DD')}`
      );

      setIsModalGuideWhatsNextVisible(true);
      startTimerGuide();

      const dob = appointment.date_birth;
      const dateBirth = `${dob.split('-')[2]}/${dob.split('-')[1]}/${dob.split('-')[0]
        }`;

      const norm = convertIntToRm(appointment.pid);

      window.open(
        `/print-label.html?patient_name=${appointment.nama
        }&norm=${`${dateBirth} | ${norm}`}&date=${context
          .moment()
          .format('DD MMMM YYYY HH:mm:ss')}&clinic=${appointment.department[0].name
        }&docter=${appointment.person.name_real}&noantrian=${checkin_antrian.no_antrian
        }&lantai=${appointment.department[0].lantai}&area=${appointment.department[0].area
        }&petunjuk=Silahkan menuju Nurse Station di`,
        'name',
        'width=10,height=10'
      );

      //print twice
      window.open(
        `/print-label.html?patient_name=${appointment.nama
        }&norm=${`${dateBirth} | ${norm}`}&date=${context
          .moment()
          .format('DD MMMM YYYY HH:mm:ss')}&clinic=${appointment.department[0].name
        }&docter=${appointment.person.name_real}&noantrian=${checkin_antrian.no_antrian
        }&lantai=${appointment.department[0].lantai}&area=${appointment.department[0].area
        }&petunjuk=Silahkan menuju Nurse Station di`,
        'tab2',
        'width=10,height=10'
      );
    } catch (e) {
      setErrorAntrean(e.message);
      context.regular.setDob('');
      context.regular.setFullName('');
    } finally {
      setLoadingGetAntrean(false);
    }
  };

  const cetakAntreanAddmission = async () => {
    console.log('cetakAntreanAddmission')
    const appointment = context.onlineCheckin.selectedPatientReg;
    const did = context.onlineCheckin.selectedPatientReg.department[0].did;
    const name = context.onlineCheckin.selectedPatientReg.department[0].name;
    const lantai =
      context.onlineCheckin.selectedPatientReg.department[0].lantai;
    const area = context.onlineCheckin.selectedPatientReg.department[0].area;

    setLoadingGetAntrean(true);
    const { data: checkin_new_patient } = await global.get(
      `web/no_antrian?did=${did}`
    );

    setLoadingGetAntrean(false);

    setIsModalGuideWhatsNextVisible(true);
    startTimerGuide();

    const dob = appointment.date_birth;
    const dateBirth = `${dob.split('-')[2]}/${dob.split('-')[1]}/${dob.split('-')[0]
      }`;

    const norm = convertIntToRm(context.onlineCheckin.selectedPatientReg.pid);

    window.open(
      `/print-label.html?patient_name=${appointment.nama
      }&norm=${`${dateBirth} | ${norm}`}&date=${context
        .moment()
        .format('DD MMMM YYYY HH:mm:ss')}&clinic=${name}&docter=${appointment.person.name_real
      }&noantrian=${checkin_new_patient.results[0].urutan
      }&lantai=${lantai}&area=${area}&penjamin=ASURANSI&petunjuk=Silahkan menuju Admission di`,
      'name',
      'width=10,height=10'
    );
  };

  if (isModalGuideWhatsNextVisible) {
    modalGuideWhatsNext = (
      <EuiModal onClose={() => null}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Langkah selanjutnya</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody className='flex items-center justify-center'>
          <TemplatePrintPoliklinik>
            <h2 className='text-lg'>
              Silahkan menuju{' '}
              {typePenjamin === 'UMUM' ? 'Nurse Station' : 'Admission'}
            </h2>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              Menutup Otomatis dalam ({timeLeft} detik)
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <div className='w-full'>
      <EuiPortal>{modalGuideWhatsNext}</EuiPortal>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <EuiText
          textAlign='center'
          grow={false}
        >
          <h1>Silahkan pilih Penjamin</h1>
        </EuiText>
      </EuiFlexGroup>
      <div className='my-20'></div>
      {errorAntrean !== '' && (
        <div className='w-1/2 mx-auto mt-8 text-center text-red-500'>
          Mohon maaf, {errorAntrean}
        </div>
      )}
      <div className='my-20'></div>
      <EuiFlexGroup gutterSize='xl'>
        <EuiFlexItem>
          <EuiCard
            icon={
              <EuiIcon
                size='xxl'
                type='agentApp'
              />
            }
            className='cursor-pointer'
            title='Pasien Umum'
            description='Antrian umum'
            onClick={() => {
              if (!loadingGetAntrean) {
                cetakAntrean('UMUM');
              }
            }}
            footer={
              <div>
                <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Pasien lama tap disini'
                  onClick={() => {
                    // cetakAntrean('UMUM');
                  }}
                  color='accent'
                  isLoading={loadingGetAntrean}
                  isDisabled={loadingGetAntrean}
                >
                  Cetak antrian Umum
                </EuiButton>
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={
              <EuiIcon
                size='xxl'
                type='filebeatApp'
              />
            }
            className='cursor-pointer'
            title='Pasien Asuransi'
            description='Antrian Asuransi'
            onClick={() => {
              if (!loadingGetAntrean) {
                cetakAntrean('ASURANSI');
              }
            }}
            footer={
              <div>
                <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Pasien baru tap disini'
                  onClick={() => {
                    // cetakAntrean('ASURANSI');
                  }}
                  color='accent'
                  isLoading={loadingGetAntrean}
                  isDisabled={loadingGetAntrean}
                >
                  Cetak antrian Asuransi
                </EuiButton>
              </div>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default Three;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/effect-cards";
import { AppContext } from "../../context/app-context";

import {
  EuiText,
  EuiTextAlign,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
} from "@edhos/ui";
import One from "./questions/one";
import Two from "./questions/two";
import Three from "./questions/three";

const Regular = function () {
  const context = useContext(AppContext);
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [currentScrollY, setCurrentScrollY] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      setCurrentScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const onChangeSlide = () => {
    if (context.showNumpad) {
      context.setShowNumpad(false);
    }

    if (swiper.isEnd) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }

    if (swiper.isBeginning) {
      setIsBeginning(true);
    } else {
      setIsBeginning(false);
    }

    setActiveIndex(swiper.activeIndex);
  };

  const beforeNext = () => {
    if (context.showNumpad) {
      context.setShowNumpad(false);
    }

    swiper.slideNext();
  };

  return (
    <div className='relative'>
      {!isBeginning && (
        <div
          className={
            currentScrollY > 60
              ? "fixed inset-x-0 top-0 z-10 pt-6 flex justify-between pb-4 bg-white shadow"
              : "fixed inset-x-0 top-0 z-10 pt-6 flex justify-between pb-4"
          }
        >
          <div
            className='ml-6'
            style={{ display: isBeginning ? "none" : "block" }}
          >
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconType='arrowLeft'
                  onClick={() => {
                    swiper.slidePrev();
                  }}
                  color={isBeginning ? "text" : "primary"}
                  isDisabled={isBeginning}
                >
                  Kembali
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>
          <div
            className='mr-6'
            style={{ display: isBeginning ? "none" : "block" }}
          >
            <EuiFlexGroup>
              {context.regular.doctor?.name &&
                swiper.activeIndex === 2 &&
                currentScrollY > 60 && (
                  <EuiFlexItem>
                    <EuiTextAlign textAlign='right'>
                      <EuiText color='accent'>
                        <h4>{context.regular.doctor.name}</h4>
                      </EuiText>
                      <EuiText color='subdued' className='mt-1'>
                        <h6>{context.regular.klinik.name}</h6>
                      </EuiText>
                    </EuiTextAlign>
                  </EuiFlexItem>
                )}

              {!context.wasBooking && (
                <EuiFlexItem grow={false}>
                  <EuiButton
                    iconType='arrowRight'
                    iconSide='right'
                    onClick={() => beforeNext()}
                    color={isLast ? "text" : "accent"}
                    isDisabled={isLast}
                  >
                    Lanjut
                  </EuiButton>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </div>
        </div>
      )}
      <Swiper
        modules={[Controller]}
        allowTouchMove={false}
        loop={false}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => onChangeSlide()}
        onSwiper={setSwiper}
      >
        <SwiperSlide>
          <div className='mx-auto max-w-4xl pb-12'>
            {activeIndex === 0 && <One beforeNext={beforeNext} />}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='mx-auto max-w-4xl pb-12'>
            {activeIndex === 1 && <Two />}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='mx-auto max-w-4xl pb-12'>
            {activeIndex === 2 && (
              <Three
                currentScrollY={currentScrollY}
                activeIndex={activeIndex}
              />
            )}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Regular;

import React, { useContext, useRef, useEffect, useState } from "react";
import {
  EuiFormRow,
  EuiButton,
  EuiText,
  EuiButtonEmpty,
  EuiCard,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlexGrid,
  EuiImage
} from "@edhos/ui";
import logo from "../../../logo.png";
import { AppContext } from "../../../context/app-context";
import { DateInput, TextInput } from "../../../components";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";

const One = function (props) {
  const navigate = useNavigate()
  const context = useContext(AppContext);
  const [searchingNoRM, setSearchingNoRM] = useState(false);

  const [isDobFilled, setIsDobFilled] = useState(false);
  const [isFullnameFilled, setIsFullNameFilled] = useState(false);

  const full_name_ref = useRef(null);
  const dob_ref = useRef(null);
  const invNumber_ref = useRef(null);

  // invNumber_ref.current.focus();

  const deactiveNumpadNKeyboard = () => {
    context.deactiveNumpad();
    context.deactiveKeyboard();
  };

  useEffect(() => {
    if (context.wasBooking) {
      setTimeout(() => {
        invNumber_ref.current.focus();
      }, 500);
    }
  }, []);

  const handleDob = ({ target: { value } }) => {
    if (context.moment(value).diff(context.moment()) < 0) {
      setIsDobFilled(true);
      deactiveNumpadNKeyboard();
    } else {
      setIsDobFilled(false);
    }

    context.regular.setDob(value);
  };

  const handleOnFocusFullName = ({ target: { value } }) =>
    context.setDefaultValueText(value);

  const handleFullName = ({ target: { value } }) => {
    context.regular.setFullName(value);

    if (value.length > 2) {
      setIsFullNameFilled(true);
    } else {
      setIsFullNameFilled(false);
    }
  };

  const checkNoRM = async () => {
    if (context.regular.fullName.length > 2) {
      setSearchingNoRM(true);
      context.setWasSearchMedrek(false);
      try {
        let dob = context.regular.dob;
        if (!dob.includes("-")) {
          dob = context.parsedDate(dob);
        }
        const { data } = await api.get(
          // `edelweiss.appointment.transaction/check_valid_rm?no_rm=${context.payment.norm}&dob=${dob}&nik=${context.regular.identityNumber}`
          `edelweiss.appointment.transaction/query_patient?nama=${context.regular.fullName}&dob=${dob}`
        );
        setSearchingNoRM(false);
        if (data?.message === "not found") {
          context.setDataPatient(null);
        } else {
          // context.setDataPatient(data);
          context.setListDataPatient(data.results);
          deactiveNumpadNKeyboard();
        }
      } catch (e) {
        setSearchingNoRM(false);
        context.setDataPatient(null);
      }
      context.setWasSearchMedrek(true);
    }
  };

  let errorKTP;

  if (context.wasSearchMedrek && context.dataPatient === null) {
    errorKTP = ["Data tidak ditemukan, Mohon coba lagi"];
  }

  return (
    <div className='w-full mx-auto'>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        {/* <EuiImage size={200} alt='Logo edelweiss hospital' src={logo} onClick={() => navigate('/')} /> */}
        <EuiText textAlign='center' grow={false}>
          <h1>Self Service Counter</h1>
          <p className='mt-[0px]' style={{}}>
            Untuk Pasien Lama (Non Asuransi dan Non Perusahaan)
          </p>
        </EuiText>
      </EuiFlexGroup>
      <div className='my-8'></div>
      <>
        {!context.listDataPatient.length && (
          <>
            <EuiFormRow
              label='Nama depan'
              className='mx-auto'
              isInvalid={
                context.wasSearchMedrek && context.dataPatient === null
              }
              error={errorKTP}
            >
              <TextInput
                inputRef={full_name_ref}
                value={context.regular.fullName}
                name='fullname'
                onChange={handleFullName}
                onFocus={handleOnFocusFullName}
                autoComplete='off'
              />
            </EuiFormRow>
            {/* <EuiFormRow label="No. HP" className="mx-auto">
                  <IdentityNumberInput
                    inputRef={identity_number_ref}
                    onBlur={onBlur}
                    value={context.regular.identityNumber}
                    name="identityNumber"
                    onChange={handleIdentityNumber}
                    autocomplete="off"
                  />
                </EuiFormRow> */}
            <EuiFormRow
              label='Tanggal lahir'
              className='mx-auto'
              helpText='Format (dd-mm-yyyy)'
            >
              <DateInput
                inputRef={dob_ref}
                value={context.regular.dob}
                name='dob'
                onChange={handleDob}
              />
            </EuiFormRow>
            <div className='flex justify-center mt-12'>
              <EuiButton
                iconType='search'
                iconSide='right'
                onClick={checkNoRM}
                color='primary'
                // isDisabled={!isDobFilled || !isFullnameFilled}
                isLoading={searchingNoRM}
              >
                {searchingNoRM && "Mohon tunggu"}
                {!searchingNoRM && "Cari data"}
              </EuiButton>
            </div>
          </>
        )}

        {context.listDataPatient.length > 0 && (
          <>
            <EuiFlexGrid
              gutterSize='xl'
              columns={2}
              className={
                context.listDataPatient.length === 1 ? "justify-center" : null
              }
            >
              {context.listDataPatient.map((dataPatient, index) => {
                return (
                  <EuiFlexItem key={index}>
                    <EuiCard
                      textAlign='left'
                      title='Info Pasien'
                      description=''
                      onClick={() => {
                        context.setDataPatient(dataPatient);
                        props.beforeNext();
                      }}
                      footer={
                        <>
                          <EuiSpacer size='m' />
                          <EuiFlexGroup justifyContent='center'>
                            <EuiFlexItem grow={false}>
                              <EuiButton
                                iconType='arrowRight'
                                iconSide='right'
                                onClick={() => {
                                  context.setDataPatient(dataPatient);
                                  props.beforeNext();
                                }}
                                color='accent'
                              >
                                Lanjut
                              </EuiButton>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </>
                      }
                    >
                      <>
                        {/* <EuiSpacer size='m' /> */}
                        <EuiText grow={false}>
                          <dl className='eui-definitionListReverse'>
                            <dt>Pasien</dt>
                            <dd>{dataPatient.full_name}</dd>
                            <dt>Jenis kelamin</dt>
                            <dd>
                              {dataPatient.sex == "m"
                                ? "laki-laki"
                                : "perempuan"}
                            </dd>
                            <dt>Alamat</dt>
                            <dd>{dataPatient.address}</dd>
                          </dl>
                        </EuiText>
                      </>
                    </EuiCard>
                  </EuiFlexItem>
                );
              })}
            </EuiFlexGrid>

            <div className='my-16'>
              <EuiFlexGroup justifyContent='center'>
                <EuiFlexItem grow={false}>
                  <EuiText grow={false} color=''>
                    *Ketika terdapat perubahan data alamat atau nama, silahkan
                    menghubungi call center/pendaftaran kami setelah berobat
                  </EuiText>
                  <EuiButtonEmpty
                    onClick={() => {
                      context.setDataPatient(null);
                      context.setListDataPatient([]);
                      context.setWasSearchMedrek(false);
                      context.regular.setFullName("");
                      context.regular.setDob("");
                    }}
                  >
                    klik disini untuk kembali ke halaman utama
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default One;

import React, { useContext, useRef, useEffect, useState } from "react";
import {
  EuiFormRow,
  EuiButton,
  EuiTextColor,
  EuiText,
  EuiHorizontalRule,
  EuiButtonEmpty,
  EuiCard,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
} from "@edhos/ui";
import { AppContext } from "../../../context/app-context";
import { DateInput, IdentityNumberInput } from "../../../components";
import TransactionInput from "../../../components/TransactionInput";
import global from "../../../services/global";
import api from "../../../services/api";
import logo from "./qr-code.png";

const One = function (props) {
  return (
    <div className='pt-24 mx-auto w-1/2 mt-24'>
      <EuiText grow={false} className='pb-40'>
        <h1>Scan QRcode ini untuk melakukan pembayaran</h1>
      </EuiText>
      <img src={logo} className='' alt='logo' />
    </div>
  );
};

export default One;

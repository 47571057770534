import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/effect-cards";
import { AppContext } from "../../context/app-context";

import {
  EuiText,
  EuiTextAlign,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
} from "@edhos/ui";
import One from "./questions/one";
import Two from "./questions/two";
// import Three from "./questions/three";
// import TemplatePrintPendaftaran from "../../../components/TemplatePrintPendaftaran";
import TemplatePrintPoliklinik from "../../components/TemplatePrintPoliklinik";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

const RegistrationCounter = function () {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [currentScrollY, setCurrentScrollY] = useState(false);

  const refPageThree = useRef();

  const onIdle = () => {
    // Do some idle action like log out your user
    context.reset();
    // swiperSlideTo(0);
    navigate('/')
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * Number(process.env.REACT_APP_AUTO_REFRESH_EVERY),
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      setCurrentScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const swiperSlideTo = (index) => {
    swiper.slideTo(index);
  };

  const scrollTop = () => {
    console.log("atas");
    window.scrollTo(0, currentScrollY - 100);
  };

  const scrollDown = () => {
    console.log("bawah");
    window.scrollTo(0, currentScrollY + 100);
  };

  const onChangeSlide = () => {
    if (context.showNumpad) {
      context.setShowNumpad(false);
    }

    if (swiper.isEnd) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }

    if (swiper.isBeginning) {
      setIsBeginning(true);
    } else {
      setIsBeginning(false);
    }

    setActiveIndex(swiper.activeIndex);
  };

  const beforeNext = () => {
    if (context.showNumpad) {
      context.setShowNumpad(false);
    }
    if (context.showKeyboard) {
      context.setShowKeyboard(false);
    }
    // if (activeIndex === 1 && context.regular.dob.length !== 10) {
    //   console.log(context.regular.dob);
    //   context.parsedDate(context.regular.dob);
    // } else {
    swiper.slideNext();
    // }
  };

  const beforePrev = () => {
    if (swiper.activeIndex === 0) {
      navigate("/");
    }
    if (swiper.activeIndex === 1) {
      //if return to home
      context.reset();
    }
    if (context.showNumpad) {
      context.setShowNumpad(false);
    }
    if (context.showKeyboard) {
      context.setShowKeyboard(false);
    }
    swiper.slidePrev();
  };

  const arrowUp = () => (
    <svg
      viewBox='0 0 24 24'
      width='24'
      height='24'
      stroke='currentColor'
      stroke-width='2'
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
      class='css-i6dzq1'
    >
      <polyline points='18 15 12 9 6 15'></polyline>
    </svg>
  );
  const arrowDown = () => (
    <svg
      viewBox='0 0 24 24'
      width='24'
      height='24'
      stroke='currentColor'
      stroke-width='2'
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
      class='css-i6dzq1'
    >
      <polyline points='6 9 12 15 18 9'></polyline>
    </svg>
  );
  return (
    <div className='relative'>
      {activeIndex == 1 && (
        <div className='fixed z-10 bottom-6 right-6'>
          <div
            onClick={scrollTop}
            className='flex items-center justify-center w-20 h-20 mb-6 text-gray-700 bg-white rounded-full shadow'
          >
            <svg
              viewBox='0 0 24 24'
              width='24'
              height='24'
              stroke='currentColor'
              strokeWidth='2'
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <polyline points='18 15 12 9 6 15'></polyline>
            </svg>
          </div>
          <div
            onClick={scrollDown}
            className='flex items-center justify-center w-20 h-20 text-gray-700 bg-white rounded-full shadow'
          >
            <svg
              viewBox='0 0 24 24'
              width='24'
              height='24'
              stroke='currentColor'
              strokeWidth='2'
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <polyline points='6 9 12 15 18 9'></polyline>
            </svg>
          </div>
        </div>
      )}
      {/* {isBeginning && (
        <EuiFlexGroup className='absolute z-10 top-6 left-6'>
          <EuiFlexItem grow={false}>
            <EuiButton
              iconType='arrowLeft'
              onClick={() => navigate("/belum-booking")}
              color={"primary"}
            >
              Kembali
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      )} */}
      {/* {!isBeginning && ( */}
      <div
        className={
          currentScrollY > 60
            ? "fixed inset-x-0 top-0 z-10 pt-6 flex justify-between pb-4 bg-white shadow"
            : "fixed inset-x-0 top-0 z-10 pt-6 flex justify-between pb-4"
        }
      >
        <div
          className='ml-6'
        // style={{ display: isBeginning ? "none" : "block" }}
        >
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton
                iconType='arrowLeft'
                onClick={() => beforePrev()}
                color={isBeginning ? "text" : "primary"}
              // isDisabled={isBeginning}
              >
                Kembali
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        <div
          className='mr-6'
          style={{ display: isBeginning ? "none" : "block" }}
        >
          <EuiFlexGroup>
            {activeIndex === 2 && context.regular.doctor?.name && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  iconType='logstashOutput'
                  iconSide='right'
                  onClick={() => refPageThree.current.toggleModalSummary()}
                  color={context.doctor === null ? "text" : "accent"}
                  isDisabled={context.doctor === null}
                >
                  Cetak Antrian
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
      </div>
      {/* )} */}
      <div className='h-screen print:hidden'>
        <Swiper
          modules={[Controller]}
          allowTouchMove={false}
          loop={false}
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => onChangeSlide()}
          onSwiper={setSwiper}
        >
          <SwiperSlide>
            <div className='flex flex-col items-center justify-center h-screen max-w-4xl mx-auto'>
              {activeIndex === 0 && <One beforeNext={beforeNext} />}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='max-w-4xl mx-auto'>
              {activeIndex === 1 && (
                <Two beforeNext={beforeNext} swiperSlideTo={swiperSlideTo} />
              )}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='max-w-4xl pb-12 mx-auto'>
              {/* {activeIndex === 2 && (
                <Three
                  ref={refPageThree}
                  currentScrollY={currentScrollY}
                  activeIndex={activeIndex}
                />
              )} */}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div id='layoutNewPatient' className='hidden print:block'>
        <TemplatePrintPoliklinik />
      </div>
    </div>
  );
};

export default RegistrationCounter;

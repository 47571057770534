import React, { useContext, useState } from "react";
import InputMask from "react-input-mask";
import { EuiFieldText, EuiInputPopover, EuiText } from "@edhos/ui";
import { AppContext } from "../context/app-context";

const TransactionInput = (props) => {
  const context = useContext(AppContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const toggleIsPopoverOpen = (shouldBeOpen = !isPopoverOpen) => {
    setIsPopoverOpen(shouldBeOpen);
  };

  const focus = () => {
    // context.deactiveNumpad();
    toggleIsPopoverOpen();
    context.setFocusTextInput(props.inputRef);
  };

  const input = (
    // <InputMask
    //   mask='99999999/PAC9999/99999'
    //   maskChar='*'
    //   value={props.value}
    //   onChange={props.onChange}
    //   placeholder='********/PAC****/*****'
    //   onFocus={() => focus()}
    //   name={props.name}
    //   alwaysShowMask={true}
    // >
    // {(inputProps) => (
    //   <EuiFieldText
    //     {...inputProps}
    //     prepend={props.sparator}
    //     inputRef={props.inputRef}
    //   />
    // )}
    // </InputMask>

    <EuiFieldText
      {...props}
      autoComplete='off'
      value={props.value}
      onChange={props.onChange}
      onFocus={() => focus()}
      prepend={props.sparator}
      inputRef={props.inputRef}
    />
  );

  return (
    <EuiFieldText
      {...props}
      autoComplete='off'
      value={props.value}
      onChange={props.onChange}
      onFocus={(e) => {
        focus();
        props.onFocus(e);
      }}
      prepend={props.sparator}
      inputRef={props.inputRef}
    />
  );
};

export default TransactionInput;

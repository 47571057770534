import { EuiButton, EuiFlexGroup, EuiFlexItem } from "@edhos/ui";
import React from "react";
import TypeAntrean from "../TypeAntrean";
import { useNavigate } from "react-router-dom";

function BelumBooking() {
  const navigate = useNavigate();
  return (
    <div className='relative'>
      <EuiFlexGroup className='absolute top-6 left-6 z-10'>
        <EuiFlexItem grow={false}>
          <EuiButton
            iconType='arrowLeft'
            onClick={() => navigate("/kiosk-booking")}
            color={"primary"}
          >
            Kembali
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <div className='print:hidden'>
        <div className='mx-auto max-w-4xl h-screen flex flex-col items-center justify-center'>
          <div className='w-full'>
            <TypeAntrean />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BelumBooking;

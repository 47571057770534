export default function TemplatePrintPoliklinik({ children }) {
  return (
    <div
      className='card-kiok-label'
      style={{
        borderWidth: 2,
        borderColor: "#000000",
        borderStyle: "solid",
        textAlign: "center",
        width: "10cm",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      {!children && (
        <>
          <h2 className='text-2xl font-semibold'>Asyifa Nadia Rakhsanada</h2>
          <h4 className='text-lg font-semibold'>00-00-40-10</h4>
          <h4 className='text-lg font-semibold'>PENJAMIN: UMUM</h4>
          <h4 className='font-semibold'>15 Januari 2022 15:08:34</h4>
          <br />
          <h4 className='font-semibold'>KLINIK THT KL</h4>
          <h4 className='font-semibold'>Desti Kusmardiani, dr. Sp. THT-KL</h4>
          <br />
          <h2>Nomor Antrian</h2>
          <div className='text-6xl font-bold'>A6</div>
          <br />
          <h2>Silahkan Menunggu Panggilan</h2>
        </>
      )}
      {children}
    </div>
  );
}

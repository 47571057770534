import { useContext } from "react";
import {
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@edhos/ui";
import { AppContext } from "../../../context/app-context";

const One = (props) => {
  const context = useContext(AppContext);

  return (
    <div className='w-full'>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <EuiText textAlign='center' grow={false}>
          <h1>Registration Counter</h1>
        </EuiText>
      </EuiFlexGroup>
      <div className='my-20'></div>
      <div className='my-20'></div>
      <EuiFlexGroup gutterSize='xl'>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='agentApp' />}
            title='Pasien Umum'
            description='Antrian umum'
            onClick={() => {
              props.beforeNext();
              context.registrationCounter.setTypePenjamin("umum");
            }}
            footer={
              <div>
                {/* <EuiSpacer size='xl' />
                <EuiButton
                  onClick={() => {
                    props.beforeNext();
                    context.registrationCounter.setTypePenjamin("umum");
                  }}
                >
                  Tap disini
                </EuiButton> */}
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='filebeatApp' />}
            title='Pasien Asuransi'
            description='Antrian Asuransi'
            onClick={() => {
              props.beforeNext();
              context.registrationCounter.setTypePenjamin("asuransi");
            }}
            footer={
              <div>
                {/* <EuiSpacer size='xl' />
                <EuiButton
                  onClick={() => {
                    props.beforeNext();
                    context.registrationCounter.setTypePenjamin("asuransi");
                  }}
                >
                  Tap disini
                </EuiButton> */}
              </div>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default One;

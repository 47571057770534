import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/effect-cards";
import { AppContext } from "../../context/app-context";

import {
  EuiText,
  EuiTextAlign,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
} from "@edhos/ui";
import One from "./questions/one";
import Two from "./questions/two";
import Three from "./questions/three";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const OnlineCheckin = function () {
  const navigate = useNavigate()
  const context = useContext(AppContext);
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [currentScrollY, setCurrentScrollY] = useState(false);

  const onIdle = () => {
    // Do some idle action like log out your user
    context.reset();
    // swiperSlideTo(0);
    navigate('/')
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * Number(process.env.REACT_APP_AUTO_REFRESH_EVERY),
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      setCurrentScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const onChangeSlide = () => {
    if (context.showNumpad) {
      context.setShowNumpad(false);
    }
    if (context.showKeyboard) {
      context.setShowKeyboard(false);
    }

    if (swiper.isEnd) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }

    if (swiper.isBeginning) {
      setIsBeginning(true);
      context.reset();
    } else {
      setIsBeginning(false);
    }

    setActiveIndex(swiper.activeIndex);
  };

  const beforeNext = () => {
    swiper.slideNext();
  };

  const beforePrev = () => {
    if (swiper.activeIndex === 0) {
      navigate("/");
    }

    swiper.slidePrev();
  };

  const swiperSlideTo = (index) => {
    swiper.slideTo(index);
  };

  return (
    <div className='relative'>
      {/* {!isBeginning && ( */}
      <div
        className={
          currentScrollY > 60
            ? "fixed inset-x-0 top-0 z-10 pt-6 flex justify-between pb-4 bg-white shadow"
            : "fixed inset-x-0 top-0 z-10 pt-6 flex justify-between pb-4"
        }
      >
        <div
          className='ml-6'
        // style={{ display: isBeginning ? "none" : "block" }}
        >
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton
                iconType='arrowLeft'
                onClick={() => beforePrev()}
                color={isBeginning ? "text" : "primary"}
              // isDisabled={isBeginning}
              >
                Kembali
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
      {/* )} */}
      <Swiper
        modules={[Controller]}
        allowTouchMove={false}
        loop={false}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => onChangeSlide()}
        onSwiper={setSwiper}
      >
        <SwiperSlide>
          <div className={`mx-auto max-w-4xl pb-12`}>
            {activeIndex === 0 && <One beforeNext={beforeNext} />}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            id='canvas'
            className={`w-full pb-12 h-[calc(100vh - 75px)]`}
            style={{ height: "calc(100vh - 86px)" }}
            onClick={(e) => {
              if (e.currentTarget.getAttribute("id") === "canvas") {
                context.input.current.focus();
              }
            }}
          >
            {activeIndex === 1 && (
              <div className='flex items-center justify-center h-screen max-w-4xl pb-12 mx-auto'>
                <Two swiperSlideTo={swiperSlideTo} beforeNext={beforeNext} />
              </div>
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center justify-center h-screen max-w-4xl pb-12 mx-auto'>
            {activeIndex === 2 && (
              <Three
                currentScrollY={currentScrollY}
                activeIndex={activeIndex}
                swiperSlideTo={swiperSlideTo}
              />
            )}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default OnlineCheckin;

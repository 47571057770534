import React, { useState, useRef, useEffect, useContext } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { AppContext } from "../context/app-context";

const KeyboardOnScreen = (props) => {
  const context = useContext(AppContext);
  const keyboard = useRef();
  const [layout, setLayout] = useState("default");
  const [defaultInput, setDefaultInput] = useState(props.value);
  const [showNext, setShowNext] = useState(false);

  useEffect(() => {
    keyboard.current.setInput(defaultInput);
    if (document.querySelector('[name="dob"]')) {
      setShowNext(true);
    } else {
      setShowNext(false);
    }
  }, []);

  const onChange = (input) => {
    props.response(input);
    // context.input.current.focus();
    // console.log("context.input: ", context.input.current);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const closeKeyboard = () => {
    props.onCloseKeyboard();
  };

  const onClickNextInput = () => {
    const el1 = document.querySelector('[name="dob"]');
    el1.focus();
  };

  return (
    <div className='fixed bottom-6 right-0 left-0 flex justify-center z-50 slide-up'>
      <div className='w-4/6'>
        <div className='w-full flex bg-[#ececec] -mb-1 rounded-t'>
          <div className='flex ml-auto items-center'>
            {showNext && (
              <button
                onClick={onClickNextInput}
                className='font-semibold text-blue-600 px-4'
              >
                NEXT
              </button>
            )}
            <button
              onClick={closeKeyboard}
              className='w-12 h-12 text-gray-500 flex justify-center items-center'
            >
              <svg
                stroke='currentColor'
                fill='none'
                strokeWidth='2'
                viewBox='0 0 24 24'
                strokeLinecap='round'
                strokeLinejoin='round'
                height='1.5em'
                width='1.5em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <desc></desc>
                <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                <rect x='2' y='3' width='20' height='12' rx='2'></rect>
                <line x1='6' y1='7' x2='6' y2='7'></line>
                <line x1='10' y1='7' x2='10' y2='7'></line>
                <line x1='14' y1='7' x2='14' y2='7'></line>
                <line x1='18' y1='7' x2='18' y2='7'></line>
                <line x1='6' y1='11' x2='6' y2='11.01'></line>
                <line x1='18' y1='11' x2='18' y2='11.01'></line>
                <line x1='10' y1='11' x2='14' y2='11'></line>
                <path d='M10 19l2 2l2 -2'></path>
              </svg>
            </button>
          </div>
        </div>
        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          onChange={onChange}
          onKeyPress={onKeyPress}
          layoutName={layout}
          // setInput={""}
        />
      </div>
    </div>
  );
};

export default KeyboardOnScreen;

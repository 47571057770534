export const requestFullScreen = () => {
  // Supports most browsers and their versions.
  var element = document.body;

  var requestMethod =
    element.requestFullScreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullScreen;

  if (requestMethod) {
    // Native full screen.
    requestMethod.call(element);
  } else if (typeof window.ActiveXObject !== "undefined") {
    // Older IE.
    var wscript = new window.ActiveXObject("WScript.Shell");
    if (wscript !== null) {
      wscript.SendKeys("{F11}");
    }
  }
};

export const updateRecentSearch = (val) => {
  const storage = localStorage.getItem("recent_search");
  if (storage) {
    let currentList = JSON.parse(window.atob(storage));
    if (currentList.length > 4) {
      currentList.pop();
    }
    currentList.unshift(val);
    const updatedValue = JSON.stringify(currentList);
    localStorage.setItem("recent_search", window.btoa(updatedValue));
  } else {
    let arrayRecent = [];
    arrayRecent.push(val);
    const str = JSON.stringify(arrayRecent);
    localStorage.setItem("recent_search", window.btoa(str));
  }
};

export const getRecentSearch = () => {
  const storage = localStorage.getItem("recent_search");
  if (storage) {
    const currentList = JSON.parse(window.atob(storage));
    return currentList;
  }
  return [];
};

export function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function convertIntToRm(integer) {
  let strIdRm = integer.toString();
  let prepadZero = strIdRm.padStart(8, "0");
  let formatedIdRm = "";
  for (var i = 0; i < prepadZero.length; i++) {
    if (i === 2 || i === 4 || i === 6) {
      formatedIdRm += "-" + prepadZero[i];
    } else {
      formatedIdRm += prepadZero[i];
    }
  }
  return formatedIdRm;
}

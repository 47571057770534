import React, { useContext, useEffect } from "react";
import {
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiImage,
} from "@edhos/ui";
import logo from "../../../logo.png";
import { AppContext } from "../../../context/app-context";
import global from "../../../services/global";
import { useNavigate } from "react-router-dom";

const One = function (props) {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    // global
    //   .get("transaksi/appointment/cari?code=APTINV20211102/PAC301/00187")
    //   .then(({ data }) => {
    //     console.log(data);
    //   });
  }, []);

  return (
    <div className='pt-24'>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <EuiImage size={200} alt='Logo edelweiss hospital' src={logo} />
        <EuiText textAlign='center' grow={false}>
          <h1>
            Selamat datang di Edelweiss Hospital <br /> Self service registrasi
            Poli
          </h1>
        </EuiText>
      </EuiFlexGroup>
      <div className='my-20'></div>
      <EuiFlexGroup gutterSize='xl'>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='agentApp' />}
            title='Sudah Booking'
            description='Sudah booking melalui Website (Web Care)'
            footer={
              <div>
                <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Ya, Saya sudah booking'
                  onClick={() => {
                    // props.beforeNext();
                    // context.setWasBooking(true);
                  }}
                >
                  Segera Hadir! Tunggu yaa
                </EuiButton>
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='filebeatApp' />}
            title='Belum Booking'
            description='Belum melakukan booking sebelumnya'
            footer={
              <div>
                <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Saya belum booking'
                  onClick={() => {
                    // props.beforeNext();
                    context.setWasBooking(false);
                    navigate(`/belum-booking`);
                  }}
                >
                  Saya belum booking
                </EuiButton>
              </div>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default One;

import {
  EuiFlexGroup,
  EuiText,
  EuiTextAlign,
  EuiFlexItem,
  EuiCard,
  EuiFlexGrid,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiSpacer,
  EuiTextColor,
  EuiButtonIcon,
} from '@edhos/ui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TextInput } from '../../../components';
import LoaderContent from '../../../components/LoaderContent';
import { AppContext } from '../../../context/app-context';
import api from '../../../services/api';
import Fuse from 'fuse.js';
import TemplatePrintPoliklinik from '../../../components/TemplatePrintPoliklinik';
import global from '../../../services/global';
import { useNavigate } from 'react-router-dom';
import { getRecentSearch, updateRecentSearch } from '../../../Helper';

function Two(props) {
  const navigate = useNavigate()
  const search_keyword_ref = useRef(null);
  const context = useContext(AppContext);
  const [timeLeft, setTimeLeft] = useState(8);
  const [isModalGuideWhatsNextVisible, setIsModalGuideWhatsNextVisible] =
    useState(false);
  const [listSearch, setListSearch] = useState([]);
  const [klinikCopied, setKlinikCopied] = useState([]);
  const [klinik, setKlinik] = useState([]);
  const [loadKlinik, setLoadKlinik] = useState(true);
  const [isModalSummaryVisible, setIsModalSummaryVisible] = useState(false);
  const [loadingPostRegister, setLoadingPostRegister] = useState(false);
  const [currentKlinik, setCurrentKlinik] = useState(null);
  const [petunjuk, setPetunjuk] = useState(null);

  let modalSummary = null,
    intervalGuide = null,
    modalGuideWhatsNext;

  useEffect(() => {
    getPoli();
    const recentSearch = getRecentSearch();
    setListSearch(recentSearch);
  }, []);

  const startTimerGuide = () => {
    intervalGuide = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft < 1) {
      clearInterval(timeLeft);
      // window.location.href = "/registration-counter";
      context.reset();
      // props.swiperSlideTo(0);
      navigate('/')
    }
  }, [timeLeft]);

  const filterLocalPoli = () => {
    let valueKeyword = context.regular.searchKeyword;

    // option fuzzy
    const options = {
      includeScore: true,
      useExtendedSearch: true,
      keys: ['display_name'],
    };

    if (valueKeyword.length > 2) {
      const fuse = new Fuse(klinikCopied, options);
      const result = fuse.search(`'${valueKeyword}`);
      // console.log(result);
      let filteredKlinik = [];
      result.map((val) => {
        filteredKlinik.push(val.item);
      });
      setKlinik(filteredKlinik);
    } else {
      setKlinik(klinikCopied);
    }
  };

  //watch keyboard on change
  useEffect(() => {
    if (context.regular.searchKeyword.length > 2) {
      filterLocalPoli();
    }
  }, [context.regular.searchKeyword]);

  const getPoli = () => {
    api
      .get(
        "edelweiss.poli?filters=[('poli_teleconsultation', '=', False), ('name', 'not ilike', 'bpjs'),  '|', ('name', 'ilike', 'klinik'), '|', ('name', 'ilike', 'fisioterapi'), '|', ('name', 'ilike', 'hemodialisa'), '|', ('name', 'ilike', 'hemodialisis'), ('name', 'ilike', 'wellness')]&order=seq"
      )
      .then(({ data }) => {
        setLoadKlinik(false);

        setKlinik(data.results);
        setKlinikCopied(data.results);
      });
  };

  const postRegisterRegular = () => {
    let typePenjamin =
      context.registrationCounter.typePenjamin === 'umum' ? 'UMUM' : 'ASURANSI';
    let wordingPetunjuk = `${context.registrationCounter.typePenjamin === 'umum'
      ? 'Silahkan menuju Admission di'
      : 'Silahkan menuju Admission di'
      // context.registrationCounter.typePenjamin === "umum"
      //   ? "Silahkan menuju Admission di"
      //   : "Silahkan menuju Nurse Station di"
      }`;
    setPetunjuk(wordingPetunjuk);
    updateRecentSearch(currentKlinik.display_name);
    const recentSearch = getRecentSearch();
    setListSearch(recentSearch);

    setLoadingPostRegister(true);
    global.get(`web/no_antrian?did=${currentKlinik.did}`).then((res) => {
      setIsModalSummaryVisible(false);
      setLoadingPostRegister(false);
      if (res.data.response === 'failed') {
      } else {
        window.open(
          `/antrian-label-patient-regular.html?noantrian=${res.data.results[0].urutan
          }&penjamin=${typePenjamin}&date=${context
            .moment()
            .format('DD MMMM YYYY HH:mm:ss')}&lantai=${'Lantai 1'}&area=${currentKlinik.area
          }&petunjuk=${wordingPetunjuk}`,
          'name',
          'width=10,height=10'
        );
        // window.location.reload();
        // window.location.href = "/kiosk-booking";
        setIsModalGuideWhatsNextVisible(true);
        startTimerGuide();
      }
    });
  };

  const handleSearchKeyword = ({ target: { value } }) => {
    context.regular.setSearchKeyword(value);
  };

  const handleOnFocusSearchKeyword = ({ target: { value } }) => {
    context.setDefaultValueText(value);
    if (value < 2) {
      setKlinik(klinikCopied);
    }
  };

  if (isModalSummaryVisible) {
    modalSummary = (
      <EuiModal onClose={() => setIsModalSummaryVisible(false)}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Sudah benar?</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TemplatePrintPoliklinik>
            <h4 className='text-lg font-semibold uppercase'>
              PENJAMIN: {context.registrationCounter.typePenjamin}
            </h4>
            <h4 className='font-semibold'>
              {context.moment().format('DD MMMM YYYY H:m:s')}
            </h4>
            <br />
            <h4 className='font-semibold'>{currentKlinik.display_name}</h4>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={postRegisterRegular}
                isLoading={loadingPostRegister}
                color='accent'
                isDisabled={loadingPostRegister}
              >
                Ya, Cetak antrian
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isModalGuideWhatsNextVisible) {
    modalGuideWhatsNext = (
      <EuiModal onClose={() => null}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Langkah selanjutnya</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TemplatePrintPoliklinik>
            <h2 className='text-lg font-semibold'>
              {petunjuk}
              (Lantai 1 Area Eksekutif)
            </h2>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              Menutup Otomatis dalam ({timeLeft} detik)
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  const filterExactPoli = (valueKeyword) => {
    let filteredKlinik = klinikCopied.filter((val) => {
      return val.display_name === valueKeyword;
    });
    setKlinik(filteredKlinik);
    context.regular.setSearchKeyword('');
  };

  return (
    <div className='py-24'>
      {modalSummary}
      {modalGuideWhatsNext}
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        {/* <EuiImage size={200} alt='Logo edelweiss hospital' src={logo} /> */}
        <EuiText grow={false}>
          <EuiTextAlign textAlign='center'>
            <h1>Mau ke Poliklinik mana?</h1>
            <EuiText color='subdued'>
              <p>
                Silahkan pilih, dengan cara <b>Tap</b> nama <b>Kliniknya</b>
              </p>
            </EuiText>
          </EuiTextAlign>
        </EuiText>
        <div className='w-1/2 mt-6'>
          <TextInput
            // type='search'
            icon='search'
            inputRef={search_keyword_ref}
            value={context.regular.searchKeyword}
            name='search_keyword'
            onChange={handleSearchKeyword}
            onFocus={handleOnFocusSearchKeyword}
            autoComplete='off'
            placeholder='Cari nama poli'
            fullWidth={true}
            className='pl-24'
            style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
            append={
              <EuiButtonIcon
                onClick={() => {
                  setKlinik(klinikCopied);
                  context.regular.setSearchKeyword('');
                  context.deactiveKeyboard();
                }}
                iconType='cross'
                aria-label='Gear this'
              />
            }
          />
          {listSearch.length > 0 && (
            <>
              <div className='mt-3 text-gray-500'>Terakhir dilihat</div>
              <div className='flex flex-wrap justify-start gap-3 mt-2'>
                {listSearch.map((val) => {
                  return (
                    <EuiButton
                      iconType='search'
                      iconSide='left'
                      size='s'
                      onClick={async () => {
                        filterExactPoli(val);
                        context.deactiveKeyboard();
                      }}
                      color='accent'
                    >
                      {val}
                    </EuiButton>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {/* </EuiFormControlLayout> */}
        {/* </EuiFormRow> */}
      </EuiFlexGroup>
      <div className='my-20'></div>
      <EuiFlexGrid
        gutterSize='xl'
        columns={2}
      >
        {loadKlinik &&
          [1, 2].map((val) => (
            <EuiFlexItem key={val}>
              <LoaderContent />
            </EuiFlexItem>
          ))}
        {/* <LayoutGroup> */}
        {!loadKlinik &&
          klinik.map((val) => {
            return (
              <EuiFlexItem key={val.id}>
                <EuiCard
                  onClick={() => {
                    setCurrentKlinik(val);
                    setIsModalSummaryVisible(true);
                  }}
                  title={val.name}
                  className={
                    context.regular.klinik.id === val.id
                      ? 'selected-card'
                      : 'border-card'
                  }
                  textAlign='left'
                />
              </EuiFlexItem>
            );
          })}
        {/* </LayoutGroup> */}
      </EuiFlexGrid>
    </div>
  );
}

export default Two;

import React from "react";
import {
  EuiText,
  EuiIcon,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  useEuiTheme,
} from "@edhos/ui";

const Numpad = (props) => {
  const { euiTheme } = useEuiTheme();

  const onTapkey = (number) => {
    // setTimeout(() => {
    // props.inputRef.current.focus();
    // }, 100);
    props.response(number);
  };

  const deactiveNumpad = () => {
    props.deactiveNumpad();
  };

  const onTapdel = () => {
    props.onTapdel();
    // props.inputRef.current.focus();
  };

  return (
    <div className='fixed bottom-6 right-0 left-0 flex justify-center z-50 slide-up'>
      {/* <div className='mx-auto'> */}
      <div className='grid grid-cols-3 bg-white rounded-lg shadow-lg'>
        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("1")}
        >
          <EuiText grow={false}>
            <h2>1</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("2")}
        >
          <EuiText grow={false}>
            <h2>2</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("3")}
        >
          <EuiText grow={false}>
            <h2>3</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("4")}
        >
          <EuiText grow={false}>
            <h2>4</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("5")}
        >
          <EuiText grow={false}>
            <h2>5</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("6")}
        >
          <EuiText grow={false}>
            <h2>6</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("7")}
        >
          <EuiText grow={false}>
            <h2>7</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("8")}
        >
          <EuiText grow={false}>
            <h2>8</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("9")}
        >
          <EuiText grow={false}>
            <h2>9</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => deactiveNumpad()}
        >
          <EuiIcon type='menuDown' size='l' />
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapkey("0")}
        >
          <EuiText grow={false}>
            <h2>0</h2>
          </EuiText>
        </button>

        <button
          className='w-24 h-24 active:bg-sky-300 transition-all border-2 border-gray-500'
          onClick={() => onTapdel()}
        >
          <EuiIcon type='eraser' size='l' color={euiTheme.colors.accent} />
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Numpad;

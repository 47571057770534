import React, { useContext, useState } from "react";
import InputMask from "react-input-mask";
import { EuiFieldText } from "@edhos/ui";
import { AppContext } from "../context/app-context";

const DateInput = (props) => {
  const context = useContext(AppContext);

  const focus = () => {
    context.setFocusInput(props.inputRef);
  };

  return (
    <InputMask
      mask='99-99-9999'
      maskChar='_'
      value={props.value}
      onChange={props.onChange}
      placeholder='DD-MM-YYYY'
      onFocus={() => focus()}
      name={props.name}
      alwaysShowMask={true}
    >
      {(inputProps) => (
        <EuiFieldText {...inputProps} inputRef={props.inputRef} />
      )}
    </InputMask>
    //   <InputMask
    //     mask='9999'
    //     maskChar={null}
    //     value={props.value}
    //     onChange={props.onChange}
    //     placeholder='Enter your PIN'>
    //     {(inputProps) => <Input {...inputProps} />}
    //   </InputMask>
  );
};

export default DateInput;

import React from 'react'

function JknKiosk() {
  return (
    <iframe className='w-screen h-screen' title='kiosk' src='http://172.5.50.2:7045/'>

    </iframe>
  )
}

export default JknKiosk

import React, {
  useContext,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  EuiPortal,
  EuiButton,
  EuiCard,
  EuiButtonIcon,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiPanel,
  EuiModalFooter,
  EuiFlexGroup,
  EuiFlexGrid,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTextAlign,
  EuiTextColor,
  EuiAvatar,
} from "@edhos/ui";
import { TextInput } from "../../../components";
import api from "../../../services/api";
import { AppContext } from "../../../context/app-context";
import LoaderContent from "../../../components/LoaderContent";
import TemplatePrintPoliklinik from "../../../components/TemplatePrintPoliklinik";
// import logo from "../../../logo.png";
import { useNavigate } from 'react-router-dom'
import qs from "qs";
import global from "../../../services/global";
import Fuse from "fuse.js";
import {
  convertIntToRm,
  getRecentSearch,
  updateRecentSearch,
} from "../../../Helper";

const Three = forwardRef((props, ref) => {
  const navigate = useNavigate()
  const context = useContext(AppContext);
  const search_keyword_ref = useRef(null);
  const [timeLeft, setTimeLeft] = useState(10);
  const [isModalGuideWhatsNextVisible, setIsModalGuideWhatsNextVisible] =
    useState(false);

  const [listSearch, setListSearch] = useState([]);

  const [klinikCopied, setKlinikCopied] = useState([]);
  const [selectedDocterTemporary, setSelectedDocterTemporary] = useState(null);
  const [klinik, setKlinik] = useState([]);
  const [loadKlinik, setLoadKlinik] = useState(true);
  const [klinikActive, setKlinikActive] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [availableLines, setAvailableLines] = useState([]);
  const [isModalClockVisible, setIsModalClockVisible] = useState(false);
  const [isModalErrorVisible, setIsModalErrorVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [isModalSummaryVisible, setIsModalSummaryVisible] = useState(false);
  const [loadingPostRegister, setLoadingPostRegister] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);
  const closeModalClock = () => setIsModalClockVisible(false);
  const showModalClock = () => setIsModalClockVisible(true);
  let modalGuideWhatsNext = "",
    intervalGuide = null;

  const startTimerGuide = () => {
    intervalGuide = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft < 1) {
      clearInterval(timeLeft);
      // window.location.href = "/self-service-counter";
      context.reset();
      // props.swiperSlideTo(0);
      navigate('/')

    }
  }, [timeLeft]);

  useImperativeHandle(ref, () => ({
    toggleModalSummary() {
      setIsModalSummaryVisible(!isModalSummaryVisible);
    },
  }));

  useEffect(() => {
    getPoli();
    const recentSearch = getRecentSearch();
    setListSearch(recentSearch);

    return context.regular.setSearchKeyword("");
  }, []);

  const getPoli = () => {
    api
      .get(
        "edelweiss.poli/get?start_day=0&filters=[('line_type','=','onsite'), ('poli_teleconsultation', '=', False), ('name', 'not ilike', 'bpjs'), ('name', 'not ilike', 'mcu'),  '|', ('name', 'ilike', 'klinik'), ('name', 'ilike', 'wellness')]&order=seq"
      )
      .then(({ data }) => {
        setLoadKlinik(false);
        setKlinik(data.results);
        setKlinikCopied(data.results);
      });
  };

  const postRegisterRegular = () => {
    updateRecentSearch(context.regular.klinik.name);
    const recentSearch = getRecentSearch();
    setListSearch(recentSearch);

    setLoadingPostRegister(true);
    const data = {
      pid: context.dataPatient.pid,
      dsid: context.regular.doctor.line.dsid,
      tgl: context.moment().format("YYYY-MM-DD"),
      time: context.regular.doctor.line.start_time,
    };

    const dob = context.dataPatient.date_birth;
    const dateBirth = `${dob.split("-")[2]}/${dob.split("-")[1]}/${dob.split("-")[0]
      }`;

    const norm = convertIntToRm(context.dataPatient.pid);

    global
      .get(
        `api/edelweiss.appointment.transaction/submit_registration?no_rm=${data.pid}&dsid=${data.dsid}&tgl=${data.tgl}&time=${data.time}`
      )
      .then((res) => {
        setIsModalSummaryVisible(false);
        setLoadingPostRegister(false);
        if (res.data.response === "failed") {
          setErrorMsg(res.data.message);
          setIsModalErrorVisible(true);
        } else {
          window.open(
            `/print-label.html?patient_name=${context.dataPatient.full_name
            }&norm=${`${dateBirth} | ${norm}`}&date=${context
              .moment()
              .format("DD MMMM YYYY HH:mm:ss")}&clinic=${context.regular.klinik.name
            }&docter=${context.regular.doctor.name}&noantrian=${res.data.no_antrian
            }&lantai=${context.regular.klinik.lantai}&area=${context.regular.klinik.area
            }&petunjuk=Silahkan menuju Nurse Station di`,
            "name",
            "width=10,height=10"
          );
          // window.location.reload();
          // window.location.href = "/kiosk-booking";
          setIsModalGuideWhatsNextVisible(true);
          startTimerGuide();
        }
      });
  };

  const chooseKlinik = (klinik) => {
    context.regular.setKlinik({ id: klinik.id, name: klinik.name });
  };

  const listDoctor = (klinik) => {
    setKlinikActive(klinik);
    showModal();
  };

  const onClickDoctor = (doc) => {
    context.regular.setKlinik({
      id: klinikActive.id,
      name: klinikActive.name,
      lantai: klinikActive.lantai,
      area: klinikActive.area,
    });
    context.regular.setDoctor({
      id: doc.name,
      name: doc.name,
      photo: doc.photo,
      line: doc.line,
    });
  };

  const stillActiveTime = (timeAvailable) => {
    const subtractTimeAvailable = context.moment(timeAvailable).subtract(10, 'minutes').format('YYYY-MM-DD HH:mm');
    const currentTime = context.moment();
    const date = context.moment(subtractTimeAvailable).diff(currentTime); // miliseconds

    if (date > 0) {
      return true;
    }
    return false;
  };

  let modal, modalClock, modalSummary, modalError, panel;

  if (isModalVisible) {
    modal = (
      <EuiModal style={{ width: 300 }} onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>{klinikActive.name}</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          {/* This modal has the following setup: */}
          <EuiText color='subdued'>
            <p>
              Silahkan pilih dokter, dengan cara <b>Tap</b>
            </p>
          </EuiText>
          <EuiSpacer />
          <EuiFlexGroup direction='column'>
            {klinikActive.available_lines.map((doc, index) => {
              return (
                <EuiFlexItem
                  grow={false}
                  key={doc.id}
                  className='cursor-pointer'
                  onClick={
                    doc.schedule[0].lines.length != 0 &&
                      doc.schedule[0].lines[0]?.end_time !== "Cuti"
                      ? () => {
                        if (doc.schedule[0].lines.length === 1) {
                          // check if time still active or passed
                          if (
                            stillActiveTime(
                              `${doc.schedule[0].date} ${doc.schedule[0].lines[0].end_time}`
                            )
                          ) {
                            setIsModalSummaryVisible(true);
                            closeModal();

                            onClickDoctor({
                              ...doc,
                              line: doc.schedule[0].lines[0],
                            });
                          }
                        }
                        if (doc.schedule[0].lines.length > 1) {
                          // onClickDoctor({
                          //   ...doc,
                          //   line: doc.schedule[0].lines[0],
                          // });
                          setSelectedDocterTemporary({
                            ...doc,
                            line: doc.schedule[0].lines[0],
                          });
                          setAvailableLines(doc.schedule[0].lines);
                          showModalClock();
                          closeModal();
                        }
                      }
                      : () => console.log("nope!")
                  }
                  style={
                    doc.schedule[0].lines.length != 0 ||
                      doc.schedule[0].lines[0]?.end_time === "Cuti"
                      ? { opacity: 1 }
                      : { opacity: 0.6 }
                  }
                >
                  <EuiFlexGroup gutterSize='s' alignItems='center'>
                    <EuiAvatar
                      size='l'
                      name={doc.name}
                      imageUrl={doc.photo}
                      className='mr-4'
                    />
                    <div>
                      <EuiText grow={false} size='m'>
                        <EuiTextColor color='subded'>
                          <h4>{doc.name}</h4>
                        </EuiTextColor>
                      </EuiText>
                      {doc.schedule[0].lines.map((line, indexline) => {
                        return (
                          <EuiText grow={false} size='xs'>
                            <EuiTextColor
                              color={
                                stillActiveTime(
                                  `${doc.schedule[0].date} ${doc.schedule[0].lines[indexline].end_time}`
                                )
                                  ? "accent"
                                  : "subdued"
                              }
                            >
                              {doc.schedule[0].lines[indexline].end_time ==
                                "Cuti" ? (
                                <h4>Sedang Cuti</h4>
                              ) : (
                                <>
                                  <h4>
                                    {doc.schedule[0].lines.length > 1 && (
                                      <span>{indexline + 1}.</span>
                                    )}
                                    {
                                      doc.schedule[0].lines[indexline]
                                        .start_time
                                    }{" "}
                                    -{" "}
                                    {doc.schedule[0].lines[indexline].end_time}
                                    {!stillActiveTime(
                                      `${doc.schedule[0].date} ${doc.schedule[0].lines[indexline].end_time}`
                                    ) ? (
                                      <span className='pl-2 text-sm'>
                                        (Waktu sudah terlewat)
                                      </span>
                                    ) : null}
                                  </h4>
                                </>
                              )}
                            </EuiTextColor>
                          </EuiText>
                        );
                      })}
                      {doc.schedule[0].lines.length == 0 && (
                        <EuiText grow={false} size='xs'>
                          <EuiTextColor color='gray'>
                            <h4>Tidak praktek hari ini</h4>
                          </EuiTextColor>
                        </EuiText>
                      )}
                    </div>
                  </EuiFlexGroup>
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Tutup
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isModalClockVisible) {
    modalClock = (
      <EuiModal style={{ width: 300 }} onClose={closeModalClock}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Pilih slot waktu</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFlexGroup>
            {availableLines.map((line, indexLine) => {
              return (
                <EuiFlexItem grow={true}>
                  <EuiButton
                    onClick={
                      stillActiveTime(
                        `${context.moment().format("YYYY-MM-DD")} ${line.end_time
                        }`
                      )
                        ? () => {
                          onClickDoctor({
                            ...selectedDocterTemporary,
                            line: line,
                          });
                          closeModal();
                          closeModalClock();
                          setIsModalSummaryVisible(true);
                        }
                        : null
                    }
                    color={
                      stillActiveTime(
                        `${context.moment().format("YYYY-MM-DD")} ${line.end_time
                        }`
                      )
                        ? "accent"
                        : "text"
                    }
                    fullWidth
                  >
                    {line.start_time} - {line.end_time}
                    {!stillActiveTime(
                      `${context.moment().format("YYYY-MM-DD")} ${line.end_time
                      }`
                    ) ? (
                      <div className='pl-2 text-xs text-red-600'>
                        (Waktu sudah terlewat)
                      </div>
                    ) : null}
                  </EuiButton>
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={closeModalClock} fill>
            Tutup
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isModalSummaryVisible) {
    modalSummary = (
      <EuiModal onClose={() => setIsModalSummaryVisible(false)}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Sudah benar?</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TemplatePrintPoliklinik>
            <h2 className='text-2xl font-semibold'>
              {context.dataPatient.full_name}
            </h2>
            <h4 className='text-lg font-semibold'>
              {context.dataPatient.norm}
            </h4>
            <h4 className='text-lg font-semibold'>PENJAMIN: UMUM</h4>
            <h4 className='font-semibold'>
              {context.moment().format("DD MMMM YYYY H:m:s")}
            </h4>
            <br />
            <h4 className='font-semibold'>{context.regular.klinik.name}</h4>
            <h4 className='font-semibold'>{context.regular.doctor.name}</h4>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={postRegisterRegular}
                isLoading={loadingPostRegister}
                color='accent'
                isDisabled={loadingPostRegister}
              >
                Ya, Cetak antrian
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isModalErrorVisible) {
    modalError = (
      <EuiModal
        style={{ width: 300 }}
        onClose={() => setIsModalErrorVisible(false)}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Gagal!</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <h5 className='text-xl font-semibold text-gray-600'>{errorMsg}</h5>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={() => setIsModalErrorVisible(false)} fill>
            Tutup
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (isModalGuideWhatsNextVisible) {
    modalGuideWhatsNext = (
      <EuiModal onClose={() => null}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Langkah selanjutnya</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <TemplatePrintPoliklinik>
            <h2 className='text-lg'>
              Silahkan menuju Nurse Station di{" "}
              {context.regular.klinik.lantai.toLowerCase().includes("lantai")
                ? context.regular.klinik.lantai
                : `Lantai ${context.regular.klinik.lantai}`}
              {" ("}
              {context.regular.klinik.area && (
                <span>
                  {context.regular.klinik.area.toLowerCase().includes("area")
                    ? context.regular.klinik.area
                    : `Area ${context.regular.klinik.area}`}
                </span>
              )}
              {")"}
            </h2>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              Menutup Otomatis dalam ({timeLeft} detik)
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  if (
    context.regular.doctor?.name &&
    props.currentScrollY < 60 &&
    props.activeIndex === 2
  ) {
    panel = (
      <div
        onClick={() => setIsModalSummaryVisible(true)}
        className='fixed inset-x-0 z-10 w-1/2 mx-auto bottom-8 slide-up'
      >
        <EuiPanel paddingSize='m'>
          <EuiText color='accent'>
            <h4>{context.regular.doctor.name}</h4>
          </EuiText>
          <EuiText color='subdued' className='mt-1'>
            <h6>{context.regular.klinik.name}</h6>
          </EuiText>
        </EuiPanel>
      </div>
    );
  }

  const filterLocalPoli = () => {
    let valueKeyword = context.regular.searchKeyword;

    // option fuzzy
    const options = {
      includeScore: true,
      useExtendedSearch: true,
      keys: ["display_name", ["available_lines", "name"]],
    };

    const fuse = new Fuse(klinikCopied, options);
    const result = fuse.search(`'${valueKeyword}`);
    // console.log(result);
    let filteredKlinik = [];
    result.map((val) => {
      // option fuzzy
      const optionsDocter = {
        includeScore: true,
        useExtendedSearch: true,
        keys: ["display_name"],
      };
      const filteredDocter = new Fuse(val.item.available_lines, optionsDocter);
      const resultDoc = filteredDocter.search(`'${valueKeyword}`);
      if (resultDoc.length) {
        let matchedDocter = [];
        resultDoc.map((doc) => {
          matchedDocter.push(doc.item);
        });
        filteredKlinik.push({ ...val.item, available_lines: matchedDocter });
      } else {
        filteredKlinik.push(val.item);
      }
    });
    setKlinik(filteredKlinik);
  };

  //watch keyboard on change
  useEffect(() => {
    if (context.regular.searchKeyword.length > 2) {
      filterLocalPoli();
    }
  }, [context.regular.searchKeyword]);

  const handleSearchKeyword = ({ target: { value } }) => {
    context.regular.setSearchKeyword(value);
  };

  const handleOnFocusSearchKeyword = ({ target: { value } }) => {
    context.setDefaultValueText(value);
    if (value < 2) {
      setKlinik(klinikCopied);
    }
  };

  const filterExactPoli = (valueKeyword) => {
    let filteredKlinik = klinikCopied.filter((val) => {
      return val.display_name === valueKeyword;
    });
    setKlinik(filteredKlinik);
    context.regular.setSearchKeyword("");
  };

  return (
    <div className='py-24'>
      {modalGuideWhatsNext}
      {modalClock}
      {modal}
      {modalSummary}
      {modalError}
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        {/* <EuiImage size={200} alt='Logo edelweiss hospital' src={logo} /> */}
        <EuiText grow={false}>
          <EuiTextAlign textAlign='center'>
            <h1>Mau ke Poliklinik mana?</h1>
            <EuiText color='subdued'>
              <p>
                Silahkan pilih, dengan cara <b>Tap</b> nama <b>Kliniknya</b>
              </p>
            </EuiText>
          </EuiTextAlign>
        </EuiText>
        {/* <EuiFormRow label='' className='w-1/2 mt-6'> */}
        {/* <EuiFormControlLayout
          icon='search'
          isLoading={true}
          clear={{
            onClick: () => {
              context.regular.setSearchKeyword("");
              context.setDefaultValueText("");
            },
          }}
          className='w-1/2 mt-6'
        > */}
        <div className='w-1/2 mt-6'>
          <TextInput
            // type='search'
            icon='search'
            inputRef={search_keyword_ref}
            value={context.regular.searchKeyword}
            name='search_keyword'
            onChange={handleSearchKeyword}
            onFocus={handleOnFocusSearchKeyword}
            autoComplete='off'
            placeholder='Cari Poli / Dokter'
            fullWidth={true}
            className='pl-24'
            style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
            append={
              <EuiButtonIcon
                onClick={() => {
                  setKlinik(klinikCopied);
                  context.regular.setSearchKeyword("");
                  context.deactiveKeyboard();
                }}
                iconType='cross'
                aria-label='Gear this'
              />
            }
          />
          {listSearch.length > 0 && (
            <>
              <div className='mt-3 text-gray-500'>Terakhir dilihat</div>
              <div className='flex flex-wrap justify-start gap-3 mt-2'>
                {listSearch.map((val) => {
                  return (
                    <EuiButton
                      iconType='search'
                      iconSide='left'
                      size='s'
                      onClick={async () => {
                        filterExactPoli(val);
                        context.deactiveKeyboard();
                      }}
                      color='accent'
                    >
                      {val}
                    </EuiButton>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {/* </EuiFormControlLayout> */}
        {/* </EuiFormRow> */}
      </EuiFlexGroup>
      <div className='my-20'></div>
      <EuiPortal>{panel}</EuiPortal>
      <EuiFlexGrid gutterSize='xl' columns={2}>
        {loadKlinik &&
          [1, 2].map((val) => (
            <EuiFlexItem key={val.id}>
              <LoaderContent />
            </EuiFlexItem>
          ))}
        {!loadKlinik &&
          klinik.map((val) => {
            return (
              <EuiFlexItem key={val.id}>
                <EuiCard
                  onClick={() => listDoctor(val)}
                  title={val.name}
                  className={
                    context.regular.klinik.id == val.id
                      ? "selected-card"
                      : "border-card"
                  }
                  textAlign='left'
                  footer={
                    <div>
                      <EuiSpacer size='s' />
                      <EuiText grow={false} size='m'>
                        <EuiTextColor color='subdued'>
                          <h6>Dokter</h6>
                        </EuiTextColor>
                      </EuiText>
                      <EuiSpacer size='s' />
                      <EuiFlexGroup gutterSize='s'>
                        {val.available_lines.map((doc, index) => {
                          if (index < 3) {
                            return (
                              <EuiFlexItem grow={false} key={doc.id}>
                                <EuiAvatar
                                  size='l'
                                  name={doc.name}
                                  imageUrl={doc.photo}
                                />
                              </EuiFlexItem>
                            );
                          }
                          if (index === 4) {
                            return (
                              <EuiFlexItem grow={false} key={doc.id}>
                                <EuiAvatar
                                  size='l'
                                  name={val.available_lines.length - 3 + "+"}
                                  imageUrl={`https://ui-avatars.com/api/?color=ed2985&background=d9d9d9&name=${val.available_lines.length - 3
                                    }%2b`}
                                />
                              </EuiFlexItem>
                            );
                          }
                        })}
                      </EuiFlexGroup>
                    </div>
                  }
                />
              </EuiFlexItem>
            );
          })}
      </EuiFlexGrid>
    </div>
  );
});

export default Three;

import axios from "axios";

const global = axios.create({
  baseURL: process.env.REACT_APP_API_URL_GLOBAL,
  headers: {
    access_token: process.env.REACT_APP_ACCESS_TOKEN,
  },
});

export default global;

import React, { useContext, useRef, useEffect, useState } from "react";
import {
  EuiFormRow,
  EuiButton,
  EuiTextColor,
  EuiText,
  EuiHorizontalRule,
  EuiButtonEmpty,
  EuiCard,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@edhos/ui";
import { AppContext } from "../../../context/app-context";
import { DateInput, IdentityNumberInput } from "../../../components";
import TransactionInput from "../../../components/TransactionInput";
import global from "../../../services/global";
import api from "../../../services/api";
import RmInput from "../../../components/RmInput";

const One = function (props) {
  const context = useContext(AppContext);
  const [searchingNoRM, setSearchingNoRM] = useState(false);
  const [isLoadingRegisterNumber, setLoadingRegisterNumber] = useState(false);
  const [dataAppointment, setDataAppointment] = useState(null);
  const identity_number_ref = useRef(null);
  const dob_ref = useRef(null);
  const norm_ref = useRef(null);
  const invNumber_ref = useRef(null);

  // invNumber_ref.current.focus();

  const onFocus = (ref) => {
    // context.activeNumpad(ref);
  };

  const deactiveNumpad = () => context.deactiveNumpad();

  const onBlur = () => {
    // context.deactiveNumpad();
    // console.log("blur");
  };

  useEffect(() => {
    if (context.wasBooking) {
      setTimeout(() => {
        invNumber_ref.current.focus();
      }, 500);
    }
  }, []);

  const handleDob = ({ target: { value } }) => context.regular.setDob(value);

  const handleNorm = ({ target: { value } }) => context.payment.setNorm(value);

  const checkNoRM = async () => {
    setSearchingNoRM(true);
    context.setWasSearchMedrek(false);
    try {
      // let dob = context.regular.norm;
      // if (!dob.includes("-")) {
      //   dob = context.parsedDate(dob);
      // }
      const { data } = await api.get(
        `edelweiss.appointment.transaction/check_valid_rm?no_rm=${context.payment.norm}&dob=&nik=`
      );
      setSearchingNoRM(false);
      if (data?.message === "not found") {
        context.setDataPatient(null);
      } else {
        context.setDataPatient(data);
        deactiveNumpad();
      }
    } catch (e) {
      setSearchingNoRM(false);
      context.setDataPatient(null);
    }
    context.setWasSearchMedrek(true);
  };

  let errorKTP;

  if (context.wasSearchMedrek && context.dataPatient === null) {
    errorKTP = ["Data tidak ditemukan"];
  }

  return (
    <div className='pt-24 mx-auto w-1/2 mt-24'>
      <div>
        {context.dataPatient === null && (
          <>
            <EuiText grow={false} className='pb-40'>
              <h1>Kiosk Pembayaran</h1>
              <p className='text-2xl'>
                Lakukan pembayaran dengan cara <br />
                masukan <b>Nomor Rekam Medis</b>
                <br />
                selanjutnya total tagihan akan muncul! 😊
              </p>
            </EuiText>
            <EuiFormRow label='No. Rekam Medis' className='mx-auto'>
              {/* <DateInput
                inputRef={dob_ref}
                value={context.regular.dob}
                name='dob'
                onChange={handleDob}
              /> */}
              <RmInput
                inputRef={norm_ref}
                value={context.payment.norm}
                name='norm'
                onChange={handleNorm}
              />
            </EuiFormRow>
            <div className='flex justify-center mt-12'>
              <EuiButton
                iconType='search'
                iconSide='right'
                onClick={checkNoRM}
                color='primary'
                isDisabled={false}
                isLoading={searchingNoRM}
              >
                {searchingNoRM && "Mohon tunggu"}
                {!searchingNoRM && "Cari data"}
              </EuiButton>
            </div>
          </>
        )}

        {/* modal info */}
        {context.dataPatient !== null && (
          <EuiModal
            onClose={() => console.log("twit")}
            initialFocus='[name=popswitch]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h1>Total tagihan</h1>
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiText>
                <p>
                  <b>Pasien</b>
                  <br />
                  <i>Ilman Manarul Qori</i>
                </p>
                <p>
                  <b>No. Rekam Medis</b>
                  <br />
                  <i>22-22-33-55</i>
                </p>
                <p>
                  <b>Rp. 10.000.000</b>
                  <br />
                  <i>SEPULUH JUTA RUPIAH</i>
                </p>
              </EuiText>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => console.log("twit")}>
                Batal
              </EuiButtonEmpty>

              <EuiButton type='submit' onClick={props.beforeNext} fill>
                Bayar Sekarang
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        )}

        {context.dataPatient !== null && false && (
          <>
            <div className='mt-16'>
              <EuiCard
                textAlign='left'
                title='Info Pasien'
                description=''
                footer={
                  <>
                    <EuiSpacer size='l' />
                    <EuiFlexGroup justifyContent='center'>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          iconType='arrowRight'
                          iconSide='right'
                          onClick={() => props.beforeNext()}
                          color='accent'
                        >
                          Lanjut
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                }
              >
                <>
                  <EuiSpacer size='m' />
                  <EuiText grow={false}>
                    <dl className='eui-definitionListReverse'>
                      <dt>Pasien</dt>
                      <dd>Madun</dd>
                      <dt>No. Rekam Medis</dt>
                      <dd>43-34-33-33</dd>
                      <dt>Tempat, Tanggal lahir</dt>
                      <dd>
                        {/* {`${context.dataPatient.birth_place}, ${context.dataPatient.birth_date}`} */}
                        Bandung, 12 Sep 1992
                      </dd>
                    </dl>
                  </EuiText>
                </>
              </EuiCard>
            </div>
            <div className='mt-16'>
              <EuiFlexGroup justifyContent='center'>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={() => context.setDataPatient(null)}>
                    Klik disini, jika data Salah!
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default One;

import React, { useContext, useRef, useEffect, useState } from "react";
import {
  EuiFormRow,
  EuiButton,
  EuiTextColor,
  EuiText,
  EuiHorizontalRule,
  EuiButtonEmpty,
  EuiCard,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFlexGrid,
} from "@edhos/ui";
import { AppContext } from "../../../context/app-context";
import {
  DateInput,
  IdentityNumberInput,
  RmInput,
  TextInput,
} from "../../../components";
import TransactionInput from "../../../components/TransactionInput";
import global from "../../../services/global";
import api from "../../../services/api";

const One = function (props) {
  const context = useContext(AppContext);
  const [searchingNoRM, setSearchingNoRM] = useState(false);
  const [isLoadingRegisterNumber, setLoadingRegisterNumber] = useState(false);
  const [dataAppointment, setDataAppointment] = useState(null);
  const [listDataPatient, setListDataPatient] = useState([]);

  const identity_number_ref = useRef(null);
  const full_name_ref = useRef(null);
  const dob_ref = useRef(null);
  const norm_ref = useRef(null);
  const invNumber_ref = useRef(null);

  // invNumber_ref.current.focus();

  const onFocus = (ref) => {
    // context.activeNumpad(ref);
  };

  const deactiveNumpadNKeyboard = () => {
    context.deactiveNumpad();
    context.deactiveKeyboard();
  };

  const onBlur = () => {
    // context.deactiveNumpad();
    // console.log("blur");
  };

  useEffect(() => {
    if (context.wasBooking) {
      setTimeout(() => {
        invNumber_ref.current.focus();
      }, 500);
    }
  }, []);

  const handleInvNumber = ({ target: { value } }) => {
    context.regular.setInvNumber(value);
    if (value.length > 15) {
      debouncedSave(value);
    }
  };

  const debouncedSave = useRef(
    debounce((nextValue) => checkNumberRegis(nextValue), 1000)
  ).current;

  function debounce(func, wait) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const handleIdentityNumber = ({ target: { value } }) => {
    context.regular.setIdentityNumber(value);
  };

  const handleDob = ({ target: { value } }) => context.regular.setDob(value);

  const handleNorm = ({ target: { value } }) => context.payment.setNorm(value);

  const handleOnFocusFullName = ({ target: { value } }) =>
    context.setDefaultValueText(value);

  const handleFullName = ({ target: { value } }) =>
    context.regular.setFullName(value);

  const checkNumberRegis = async (nextValue) => {
    // .get("transaksi/appointment/cari?code=APTINV20211102/PAC301/00187")
    const keyword = nextValue.includes("APTINV")
      ? nextValue
      : `APTINV${nextValue}`;
    setLoadingRegisterNumber(true);
    try {
      const { data } = await global.get(
        `transaksi/appointment/cari?code=${keyword}`
      );
      setLoadingRegisterNumber(false);
      setDataAppointment(data.results[0]);
      console.log("results", data.results);
      invNumber_ref.current.blur();
    } catch (e) {
      if (e.message === "Request failed with status code 404") {
        console.log("notfound", e.message);
        setDataAppointment(null);
        setLoadingRegisterNumber(false);
      }
    }
  };

  const checkNoRM = async () => {
    setSearchingNoRM(true);
    context.setWasSearchMedrek(false);
    try {
      let dob = context.regular.dob;
      if (!dob.includes("-")) {
        dob = context.parsedDate(dob);
      }
      const { data } = await api.get(
        // `edelweiss.appointment.transaction/check_valid_rm?no_rm=${context.payment.norm}&dob=${dob}&nik=${context.regular.identityNumber}`
        `edelweiss.appointment.transaction/query_patient?nama=${context.regular.fullName}&dob=${dob}`
      );
      setSearchingNoRM(false);
      if (data?.message === "not found") {
        context.setDataPatient(null);
      } else {
        // context.setDataPatient(data);
        setListDataPatient(data.results);
        deactiveNumpadNKeyboard();
      }
    } catch (e) {
      setSearchingNoRM(false);
      context.setDataPatient(null);
    }
    context.setWasSearchMedrek(true);
  };

  let errorKTP;

  if (context.wasSearchMedrek && context.dataPatient === null) {
    errorKTP = ["Server sedang penuh, Mohon coba lagi"];
  }

  return (
    <div className='pt-24 mx-auto w-full mt-24'>
      <>
        {!listDataPatient.length && (
          <>
            <EuiFormRow
              label='Nama lengkap'
              className='mx-auto'
              isInvalid={
                context.wasSearchMedrek && context.dataPatient === null
              }
              error={errorKTP}
            >
              <TextInput
                inputRef={full_name_ref}
                value={context.regular.fullName}
                name='fullname'
                onChange={handleFullName}
                onFocus={handleOnFocusFullName}
                autocomplete='off'
              />
            </EuiFormRow>
            {/* <EuiFormRow label="No. HP" className="mx-auto">
                  <IdentityNumberInput
                    inputRef={identity_number_ref}
                    onBlur={onBlur}
                    value={context.regular.identityNumber}
                    name="identityNumber"
                    onChange={handleIdentityNumber}
                    autocomplete="off"
                  />
                </EuiFormRow> */}
            <EuiFormRow label='Tanggal lahir' className='mx-auto'>
              <DateInput
                inputRef={dob_ref}
                value={context.regular.dob}
                name='dob'
                onChange={handleDob}
              />
            </EuiFormRow>
            <div className='flex justify-center mt-12'>
              <EuiButton
                iconType='search'
                iconSide='right'
                onClick={checkNoRM}
                color='primary'
                isDisabled={false}
                isLoading={searchingNoRM}
              >
                {searchingNoRM && "Mohon tunggu"}
                {!searchingNoRM && "Cari data"}
              </EuiButton>
            </div>
          </>
        )}

        {listDataPatient.length > 0 && (
          <>
            <EuiFlexGrid
              gutterSize='xl'
              columns={2}
              className={listDataPatient.length === 1 ? "justify-center" : null}
            >
              {listDataPatient.map((dataPatient, index) => {
                return (
                  <EuiFlexItem key={index}>
                    <EuiCard
                      textAlign='left'
                      title='Info Pasien'
                      description=''
                      footer={
                        <>
                          <EuiSpacer size='m' />
                          <EuiFlexGroup justifyContent='center'>
                            <EuiFlexItem grow={false}>
                              <EuiButton
                                iconType='arrowRight'
                                iconSide='right'
                                onClick={() => {
                                  context.setDataPatient(dataPatient);
                                  props.beforeNext();
                                }}
                                color='accent'
                              >
                                Lanjut
                              </EuiButton>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </>
                      }
                    >
                      <>
                        {/* <EuiSpacer size='m' /> */}
                        <EuiText grow={false}>
                          <dl className='eui-definitionListReverse'>
                            <dt>Pasien</dt>
                            <dd>{dataPatient.full_name}</dd>
                            <dt>Jenis kelamin</dt>
                            <dd>
                              {dataPatient.sex == "m"
                                ? "laki-laki"
                                : "perempuan"}
                            </dd>
                            <dt>Alamat</dt>
                            <dd>{dataPatient.address}</dd>
                          </dl>
                        </EuiText>
                      </>
                    </EuiCard>
                  </EuiFlexItem>
                );
              })}
            </EuiFlexGrid>

            <div className='my-16'>
              <EuiFlexGroup justifyContent='center'>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    onClick={() => {
                      context.setDataPatient(null);
                      setListDataPatient([]);
                      context.setWasSearchMedrek(false);
                      context.regular.setFullName("");
                      context.regular.setDob("");
                    }}
                  >
                    Klik disini, jika data Salah!
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default One;

import React, { useContext } from "react";
import { EuiFieldText } from "@edhos/ui";
import { AppContext } from "../context/app-context";

const IdentityNumberInput = (props) => {
  const context = useContext(AppContext);

  const focus = () => {
    context.setFocusInput(props.inputRef);
  };

  return <EuiFieldText {...props} onFocus={() => focus()} />;
};

export default IdentityNumberInput;

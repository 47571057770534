import logo from "./logo.png";
import "./App.css";
import CardMenuKiosk from "./components/CardMenuKiosk";

function App() {
  return (
    <div className='max-w-4xl mx-auto py-16 flex flex-col justify-center items-center h-[85vh]'>
      {/* <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className='App-link'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React
        </a>
      </header> */}
      <div className="divider my-12 font-bold text-4xl text-center text-slate-800/80">KIOSK</div>
      <div>
        <CardMenuKiosk />
      </div>
    </div>
  );
}

export default App;

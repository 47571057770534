import React, { useContext, useEffect } from "react";
import {
  EuiButton,
  EuiCard,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiImage,
} from "@edhos/ui";
import logo from "../../../logo.png";
import { AppContext } from "../../../context/app-context";
import global from "../../../services/global";
import { useNavigate } from "react-router-dom";

const One = function (props) {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    // global
    //   .get("transaksi/appointment/cari?code=APTINV20211102/PAC301/00187")
    //   .then(({ data }) => {
    //     console.log(data);
    //   });
  }, []);

  return (
    <div className='pt-24'>
      <EuiFlexGroup
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <EuiImage size={200} alt='Logo edelweiss hospital' src={logo} onClick={() => navigate('/')} />
        <EuiText textAlign='center' grow={false}>
          <h1>Online Check In Counter</h1>
          {/* <EuiText color='danger'>
            <h1>(PELATIHAN)</h1>
          </EuiText> */}
        </EuiText>
      </EuiFlexGroup>
      <div className='my-20'></div>
      <EuiFlexGroup gutterSize='xl'>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='agentApp' />}
            // className='opacity-50'
            title='Booking Via Website'
            description='Pasien sudah Booking via care.edelweiss.id'
            // description='Segera Hadir!'
            onClick={() => {
              props.beforeNext();
              context.onlineCheckin.setBookingVia("web");
            }}
            footer={
              <div>
                {/* <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Ya, Saya sudah booking'
                  onClick={() => {
                    props.beforeNext();
                    context.onlineCheckin.setBookingVia("web");
                  }}
                >
                  Tap disini
                </EuiButton> */}
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon size='xxl' type='filebeatApp' />}
            title='Booking Via Telp/WhatsApp'
            description='Pasien sudah Booking via Telepon / WhatsApp'
            onClick={() => {
              props.beforeNext();
              context.onlineCheckin.setBookingVia("wa");
            }}
            footer={
              <div>
                {/* <EuiSpacer size='xl' />
                <EuiButton
                  aria-label='Booking Telp/WhatsApp'
                  onClick={() => {
                    props.beforeNext();
                    context.onlineCheckin.setBookingVia("wa");
                  }}
                >
                  Tap disini
                </EuiButton> */}
              </div>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default One;
